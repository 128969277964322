
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #32a8df;
}

/* Mobile Navigation */
.mobile_nav {
  background-image: linear-gradient(-300deg, #3a3480, #1d4e84) !important;
}
.home_page_menu li::after {
  display: none;
}

/* Custom Styles */
/* Home Page */
ul,li,button,
.MuiButton-root {
  font-family: 'Lato', sans-serif !important;
}
.hamburger_btn {
  color: #32a8df !important;
}
.page_wrapper header {
  z-index: 1000;
  /* background-color: #1a1a1a !important; */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 0;
}
.home_left_navbar {
  display: flex;
  margin-left: 20% !important;
}
.home_left_navbar li a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 24px;
  color: #fff;
  padding: 0;
}
.home_left_navbar li a:hover,
.home_left_navbar li a.active {
  color: #32a8df;
}
.home_main_nav {
  justify-content: space-between;
}
button.auth_btn {
  border: 1px solid #32a8df;
  background: #32a8df;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 5px 12px;
  transition: 0.2s all;
}
button.auth_btn svg {
  margin-right: 5px;
}
button.auth_btn a {
  color: #fff;
}
button.auth_btn:hover {
  background: #f7a100;   
  border: 1px solid #ffd800;
}
button.auth_btn:hover a {
  color: #fff !important;
}
button.auth_btn.blue_btn:hover {
  color: #fff;
}
.main_navbar {
  display: flex;
}
.page-header h1 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}
.page-header h3 {
  color: #6ad0ff;
  font-size: 20px;
  font-weight: 500;
}
.page-header h3 span {
  color: #fff;
}
.page-header p {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}
.banner_slider .slick-prev,
.banner_slider .slick-next {
  top: unset;
  bottom: -50px;
}
.banner_slider .slick-prev {
  left: 0;
}
.banner_slider .slick-next {
  right: unset;
  left: 40px;
}
.banner_slider .slick-prev:before,
.banner_slider .slick-next:before {
  color: #32a8df;
}
.banner_slider .slick-prev:before {
  content: "\f284";
  font-family: bootstrap-icons !important;
}
.banner_slider .slick-next:before {
  content: "\f285";
  font-family: bootstrap-icons !important;
}
.float_img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.float_img img {
  position: absolute;
  animation: NewsletterAnimated3 12s infinite linear alternate;
}
.float_img img:first-child {  
  right: -120px;
  top: 60px;
}
.float_img img:nth-child(2) {
  top: 40%;
  left: -80px;
}
.float_img img:nth-child(3) {
  top: 20%;
  left: 150px;
}
.float_img img:nth-child(4) {
  bottom: 20%;
  right: 100px;
}
select.custom-select.language_select {
  background: transparent;
  border: none;
  color: #969696;
  font-size: 16px;
  box-shadow: none;
  padding: 0 15px;
}
.noti_ul {
  padding: 0 !important;
}
.noti_ul b {
  display: none !important;
}
.noti_ul button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 50px;
}
.noti_head {
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  font-size: 18px;
}
.no_noti {
  color: #9a9696;
  font-size: 14px;
  text-align: center;
  padding: 20px 0px;
  margin-bottom: 0px;
  white-space: normal;
}
.noti_ul ul li {
  background-color: transparent !important;
  box-shadow: none !important;
}
.noti_ul .MuiPaper-rounded {
  background-color: #0f0f0f;
}
.noti_ul img {
  width: 150px;
}
.menu_icons {
  color: #969696;
}
.see_all_link {
  font-size: 15px;
  font-weight: 600;
  color: #32a8df !important;
  width: 100%;
  text-align:center;
  text-decoration: underline !important;
  display: block;
}
.see_all_link:hover {
  color:#787878 !important;
}
.theme_switch_btn .btn.active {
	background-color: #0f0f0f;
}
.theme_switch_btn .btn {
	background-color: #000;
  box-shadow: none;
  padding: 5px 10px;
}
.home_page_menu ul {
  align-items: center;
  display: flex;
  justify-content: center;
}
.theme_switch_btn i {
  font-size: 14px;
  color: #3c3c3c;
}
.theme_switch_btn .btn.active i {
  color: #32a8df;
}
.noti_ul sup {
  color: #32a8df;
  font-size: 12px;
}
.theme_switch_btn {
  margin-right: 10px;
}
.main_title {
  font-size: 35px;
  font-weight: 700;
}
.main_content {
  color: #657191;
  font-size: 16px;
}
.main_link {
  color: #32a8df;
  font-size: 16px;
  font-weight: 700;
}
li.menu_dropdown {
  padding: 5px;
}
li.menu_dropdown button {
  color: #fff;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
  padding: 0 !important;
  line-height: 16px;
  min-width: auto;
}
li.menu_dropdown .MuiPaper-root {
  background-color: #0f0f0f;
}
li.menu_dropdown .MuiPaper-root ul li {
  padding: 10px;
}
li.menu_dropdown .MuiPaper-root ul li:hover {
  background-color: #000;
  box-shadow: none;
}
li.menu_dropdown button:hover {
  color: #32a8df;
}
.primary_checkbox .custom-control-input {
  z-index: 1;
}
.primary_checkbox .custom-control-label::before {
  top: 0;
  left: -20px;
  background: #262626;
  border-color: #262626;
}
.primary_checkbox .custom-control-label::after {
  top: 0;
  left: -20px;
}
.primary_checkbox .custom-control-input:checked~.custom-control-label::before {
  border-color: #32a8df;
}
.primary_checkbox label {
  font-size: 14px;
}
button.banner_btn {
  width: 100%;
  background-image: linear-gradient(#e7c65f, #d09514);
  border: 2px solid #dfb544;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 8px;
}
button.banner_btn:hover {
  color: #dfb544;
  border: 2px solid #dfb544;
  background-image: none;
}
.form_note {
  color: #b9b9b9 !important;
  font-size: 14px !important;
}
.form_note a {
  color: #32a8df;
}
.bottom_banner img {
  border-radius: 10px;
  margin-bottom: 30px;
}
.banner_ticker_panel .slick-slide {
  margin: 0 auto 0px;
}
.banner_ticker_single {
  width: 250px !important;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  display: flex !important;
  align-items: center;  
  background: #193b72;
}
.banner_ticker_single img {
  width: 36px;
  margin-right: 8px;
}
.green_txt {
  color: #2bc155 !important;
}
.red_txt {
  color: #fb1d46 !important;
}
.orange_txt {
  color: #f68819 !important;
}
.yellow_txt {
  color: #32a8df !important;
}
.banner_ticker_single h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #e1e1e1;
}
.banner_ticker_single h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.banner_ticker_single h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.banner_ticker_single .badge {
  font-size: 12px;
  padding: 5px 7px;
  font-weight: 600;
}
.green_badge {
  background: #3bd20d;
  color: #000000;
}
.red_badge {
  background: #fb1d46;
  color: #ffffff;
}
.banner_ticker_panel {
  background-color: #fff;
  border-radius: 8px;
}
.features_section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
}
.features_section::before{
  content: "";
  position: absolute;
  /* background: url("../src/assets/images/benefitbg1.png"); */
  bottom: 0;
  right: 0;
  width: 179px;
  height: 273px;
  background-repeat: no-repeat;
  background-position: center;
}
.features_section::after{
  content: "";
  position: absolute;
  /* background: url("../src/assets/images/benefitbg2.png"); */
  top: -36px;
  left: 0;
  width: 339px;
  height: 531px;
  background-repeat: no-repeat;
  background-position: center;
}
.rewards_section {
  /* background: url("../src/assets/images/section_bg.png"); */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: cover;
  padding: 80px 0;
  overflow: hidden;
}
.getstarted_section {
  background: #fafafc;
}
.rewards_section h2 {
  color: #fff;
  font-size: 24px;
}
.graph_bg {
  background-image: linear-gradient(180deg, rgb(255 255 255 / 10%), rgb(255 255 255 / 10%));
  padding: 30px 20px;
  border-radius: 10px;
}
.graph_bg .exchange_value h2 {
  font-size: 28px;
  color: #fff;
}
.graph_bg p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}
.getstarted_section h6 {
  color: #32a8df;
  font-size: 20px;
  font-weight: 600;
  text-transform: unset;
  margin: 0;
}
.getstarted_section h2 {
  color: #0d1543;
  font-size: 45px;
  font-weight: 700;
  margin: 0;
}
.detail_panel_single img {
  margin-right: 10px;
}
.detail_panel_single {
  background: #2d5b8f;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  border:2px solid transparent;
}
.detail_panel_single:hover{
background:#32649c;
border:2px solid #3392cc;
}
.detail_panel_single h2 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.detail_panel_single h3 {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.feature_panel_single { 
  margin: 15px 0;
  background: #fff;
  cursor: default;
  transition: 0.2s all;
  box-shadow: 0 0 10px 4px rgb(60 77 175 / 5%);
  padding: 15px 0px;
  border-radius: 8px;
  transition: 0.2s all;
}
.feature_panel_single:hover {
  transform: translateY(-10px);
  background: linear-gradient(45deg, #1d4e84, #363781);
  color:#fff;
}
.feature_panel_single:hover h2,.feature_panel_single:hover p{
  color:#fff;
}
.feature_panel_single .media {
  align-items: center;
}
.feature_panel_single img {
  margin-right: 8px;
  margin:8px;
}
.feature_panel_single h2  {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0 0 10px;
}
.feature_panel_single p {
  color: #657191;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.benefits_img {
  width: 700px;
}
.feature_panel .feature_panel_single:nth-child(even) {
  margin-top: 20px;
}
.features_section .float_img img {
  left: 40%;
  top: -30px;
}
.advantages_section_first {
  /* background: #fff url("../src/assets/images/liqiditybg.png"); */
  background-position: center;
  background-size: 100% 58%;
  background-repeat: no-repeat;
  background-color: white;
}
.starts_section {
  /* background: url("../src/assets/images/peerbg.png"); */
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
@keyframes NewsletterAnimated3 {
	0% {
		transform: translateY(0)
	}
	25% {
		transform: translateY(-20px)
	}
	50% {
		transform: translateY(0)
	}
	75% {
		transform: translateY(20px)
	}
	100% {
		transform: translateY(0)
	}
}
.footer_menu_list li {
  display: block;
  text-align: left;
}
footer.footer_home {
  background-color: #2a2872;
  padding: 50px 0 20px;
}
.footer_menu_list li a {
  padding: 5px 0;
  font-size: 13px;
  color: #8e8cce;
  transition: 0.2s all;
}
footer ul li a {
    color: inherit;
    padding: 0.9375rem;
    font-size: 12px;
    border-radius: 3px;
    position: relative;
    display: block;
}
.footer_menu_list li a:hover {
  color: #fff;
}
.subscribe_label {
  font-size: 16px;
  margin-bottom: 20px;
  color: #000;
}
.footer_top_home {
  text-align: left;
  border-bottom: 1px solid rgb(255 255 255 / 8%);
  padding-bottom: 15px;
}
.footer_top_home div::nth-child(1){
  background:red;
}
.footer_top_home .subscribe_label {
  font-size: 15px;
  display: block;
  color: #8e8cce;
  margin-top: 8px;
}
.footer_top_home h2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.subscribe_form {
  position: relative;
}
input.subscribe_inp {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  height: 42px;
  padding: 8px 10px 10px 10px;
  width: 100%;
}
button.subscribe_btn {
  font-size: 16px;
  font-weight: 600;  
  height: 42px;
  padding: 3px 20px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.2s all;
  text-transform: capitalize;
}
.footer_bottom_home {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 5px 0 10px;
}
.copyryt_txt {
  color: #928fed;
  font-size: 13px;
}
footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}
.footer_social_links ul li {
    display: inline-block;
}
.footer_social_links li a {
  color: #7674af;
  font-size: 18px;
  transition: 0.2s all;
  padding: 15px 12px;
}
.footer_social_links li a:hover,
.footer_bottom_home ul li a:hover {
  color: #fff;
}
.footer_bottom_home ul li a {
  color: #928fed;
  font-size: 13px;
}
.footer_top_home input.subscribe_inp::placeholder {
  color: #928fed;
  font-size: 14px;
}
.footer_top_home input.subscribe_inp {
  border: 1px solid #5d5baa;
  background-color: transparent;
  color: #fff;
}
.footer_top_home button.subscribe_btn {
  font-weight: 500;
  text-transform: uppercase;
}
.primary_inp {
  border: 1px solid #262626;
  background: #262626;
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px 5px 15px;
  height: 40px;
  font-size: 14px;
  color: #fff;
}
.primary_inp::placeholder {
  font-size: 14px;
  color: #b9b9b9;
}
.primary_modal .custom-control-label {
  position: unset;
  box-shadow: none;
  font-size: 16px;
  color: #000;
}
.primary_modal .custom-control-label::before {
  top: 0;
  box-shadow: none;
}
.primary_modal .custom-control-label::after {
  top: 0;
}
/* .primary_modal .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
} */

/* Login & Register */
.auth_padding_container {
  padding-top: 20vh !important;
  padding-bottom: 100px !important;
}
.auth_panel {
  /* background: url("../src/assets/images/auth_bg.png") #fff; */
  background-size: cover;
}
.relative {
  position: relative;
}
/* Dashboard */
.inner_pageheader {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 500px;
}
.inner_pageheader > div {
  min-height: inherit;
}
.inner_pageheader > div > div.MuiGrid-grid-lg-2 {
  background: #ededed;
}
.inner_heading_wrapper {  
  background-size: cover !important;
  min-height: 330px !important;
  display: flex;
  align-items: center;
}
/* .pool_heading_wrap {
  background: url("../src/assets/images/pool_banner_bg.png") no-repeat center ;
} */
.exchange_heading_wrap {
  background: url("../src/assets/images/exchange_banner_bg.jpg") no-repeat center ;
}
/* .liquidity_heading_wrap {
  background: url("../src/assets/images/liquidity_banner_bg.png") no-repeat center ;
} */
.wallet_heading_wrap {
  background: url("../src/assets/images/wallet_banner.png") no-repeat center ;
}
.dashboard_heading_wrap {
  background: url("../src/assets/images/dashboard_banner.png") no-repeat center ;
}
.settings_heading_wrap {
  background: url("../src/assets/images/settings_banner.png") no-repeat center ;
}
.kyc_heading_wrap {
  background: url("../src/assets/images/kyc_banner.png") no-repeat center ;
}
.profile_heading_wrap {
  background: url("../src/assets/images/profile_banner.png") no-repeat center ;
}
.support_heading_wrap {
  background: url("../src/assets/images/support_banner.png") no-repeat center ;
}
button.inner_primary_btn {
  background: #32a8df;
  border: 2px solid #32a8df;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 25px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
button.inner_primary_btn:hover {
  color: #32a8df;
  border: 2px solid #32a8df;
  background: transparent;
}
button.inner_primary_btn a {
  color: #fff;
}
button.inner_primary_btn:hover a {
  color: #32a8df;
}
.inner_heading_wrapper h2 {
  color: #fff;
  font-size: 50px;
  line-height: 56px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper h4 {
  color: #e0e0e0;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper p {
  color: #6ad0ff;
  font-size: 18px;
  font-weight: 400;
  max-width: 60%;
  line-height: 26px;
  margin-bottom: 0;
}
.inner_content_wrapper {
  background: #e2e4f4;
  padding-top: 30px;
}
button.follow_btn {
  background-image: linear-gradient(#e7c65f, #d09514);
  border: 2px solid #dfb544;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 10px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
button.follow_btn a {
  color: #fff;
}
button.full_btn {
  background-color: #ededed;
  border: 1px solid #d2d2d2;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 10px;
  color: #5a5a5a;
  transition: 0.2s all;
  border-radius: 5px;
}
.inner_content_wrapper_plain {
  margin-top: 120px;
}
.whitebox {
  background: #fff;
  box-shadow: 0 0 10px 1px rgb(56 66 71 / 5%);
  margin: 30px 0;
  padding: 20px;
  border-radius: 5px;
}
.follow_trade_head {
  display: flex;
  justify-content: space-between;
}
.follow_trade_head i {
  font-size: 20px;
  color: #32a8df;
  margin-right: 5px;
}
.follow_trade_head span {
  font-size: 20px;
  color: #000000;
}
.follow_trade_head p {
  margin-bottom: 0;
  font-size: 14px;
  color: #787878;
}
.trader_contract_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
button.contract_pair {
  position: relative;
  padding: 5px 10px;
  border: 1px solid #f1f3f6;
  background: #f1f3f6;
  color: #787878;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  transition: all .35s;
  margin: 8px 10px 0;
}
.contract_label {
  font-size: 14px;
  color: #000;
  margin-right: 15px;
}
.contract_pair.active, 
.contract_pair:hover {
  border-color: #32a8df;
  background-color: #fffaee;
  color: #494949;
}
.contract_pair.active:after {
  content: " ";
  position: absolute;
  bottom: -1px;
  right: -1px;
  display: block;
  width: 15px;
  height: 15px;
  /* background: url("../src/assets/images/button_tick.svg") 50% no-repeat; */
  background-size: cover;
}
.custom-control {
  min-height: initial;
}
.custom-control-label::before {
  top: 0;
  left: -20px;
}
.custom-control-label::after {
  top: 0;
  left: -20px;
}
.copy_setting_box {
  margin-top: 30px;
  padding: 10px 0 10px 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.copy_unify_label {
  color: #000;
  font-size: 14px;
  display: inline-block;
  width: 120px;
  margin-right: 15px;
}
.copy_unify_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.copy_input_lever {
  display: flex;
  align-items: flex-end;
  margin: 0 15px;
}
span.input-lever-text {
  color: #787878;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
}
.lever_input {
  width: 50px;
  text-align: center;
  height: initial;
}
.copy_input_lever_box {
  display: flex;
  margin: 10px 0;
}
button.copy_inp_cal {
  color: #32a8df;
  background: #f5f5f5;
  font-size: 20px;
  padding: 15px 0;
  margin: 0 5px;
  height: 0;
  width: 40px;
  min-width: initial;
}
.primary_modal .modal-header h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.primary_modal .modal-header .close {
  color: #000;
}
.setting_overview {
  margin-bottom: 10px;
  padding: 16px 13px 16px 11px;
  background: #fff;
  border: 1px solid #dee1e4;
  border-radius: 6px;
}
.dialog_pair {
  color: #32a8df;
  font-size: 16px;
  font-weight: 700;
}
.setting_overview div span {
  font-size: 14px;
  color: #5d5e65;
  display: inline-block;
  width: 49%;
  text-align: left;
}
.setting_overview div span:nth-child(2) {
  font-size: 14px;
  color: #000;
  text-align: right;
}
.setting_overview div {
  margin: 10px 0;
}
.primary_form select.custom-select {
  border: 1px solid #d9d9d9;
  background: #f5f5f5 url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
}
.primary_form .primary_inp_inner {
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  width: 100%;
  height: 41px;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}
.pointer {
  cursor: pointer;
}
.holder {
  height: 50px;
  width: 50px;
  border: 2px solid #252525;
  border-radius: 100%;
  margin: 0 auto;
}
.holder img {
  border-radius: 100%;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}
input[type="file"]#photo {
  margin-top: -5px;
  opacity: 0;
  z-index: 999;
}
.profile_edit_panel {
  position: relative;
  margin-bottom: -15px;
}
.profile_edit_icon {
  position: absolute;
  top: -10px;
  z-index: 0;
  left: 40px;
  right: 0;
  text-align: center;
}
.profile_edit_icon i {
  background: #32a8df;
  color: #000;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}
.custom-switch .custom-control-label::after {
  top: 2px;
}
.copy_trade_user_data {
  display: flex;
  align-items: center;
}
.copy_trade_user_data div {
  text-align: center;
  margin: 0 30px;
}
.copy_trade_user_data h3 {
  font-size: 16px;
  margin: 0;
}
.copy_trade_user_data p {
  color: #787878;
  font-size: 12px;
}
.badge.badge-danger {
  background: #feecec;
  color: #ff0000;
  font-size: 13px;
  text-transform: capitalize;
  padding: 8px 7px;
}
.badge_light {
  border-radius: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #5a5a5a;
  margin: 15px 0;
}
.trade_order_pair {
  background: #f5f5f5;
  font-size: 14px;
  color: #787878;
  padding: 5px 12px;
  border-radius: 3px;
  margin: 0 10px !important;
  cursor: default;
}
.form_exchange_grp {
  display: flex;
  align-items: flex-end;
}
.form_exchange_grp img {
  margin: 12px 20px;
}
.form_exchange_grp input {
  font-size: 14px;
}
.buy_crypto_panel {
  background: #32a8df;
  border-radius: 5px;
  padding: 30px 30px 10px;
}
.buy_crypto_append_inp {
  position: absolute;
  top: 1px;
  width: 90px;
  right: 5px;
  border: 0;
  border-radius: 0;
  height: 33px;
  box-shadow: none !important;
}
.buy_crypto_panel h2 {
  margin: 0;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
}
.buy_crypto_panel label {
  color: #ffffff;
}
.buy_crypto_eq_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buy_crypto_eq_value h3 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 0;
}
.buy_crypto_eq_value h3 span {
  color: #f5f5f5;
}
.buy_crypto_service {
  border: 1px solid #32a8df;
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px 10px 20px;
  transition: 0.2s all;
  cursor: pointer;
  text-align: center;
}
.buy_crypto_service:hover {
  box-shadow: 0 8px 16px -4px rgb(219 172 55 / 80%);
}
.buy_crypto_service h3 {
  color: #32a8df;
  font-size: 26px;
  font-weight: 700;
  margin: 10px 0 0;
}
.buy_crypto_service h4 {
  font-size: 14px;
  color: #5a5a5a;
  font-weight: 700;
  margin: 0;
}
.buy_crypto_service p {
  color: #5a5a5a;
  font-size: 14px;
  margin: 0;
}
.buy_crypto_service_header {
  display: flex;
  justify-content: space-between;
}
.buy_crypto_service_header h2 {
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  margin: 0;
}
.buy_crypto_service_header p {
  font-size: 14px;
  color: #5d5e65;
  margin-bottom: 0;
}
.custom_timer {
  color: #dcaf3d !important;
  margin-left: 10px;
  background: #f5f5f5;
  border-radius: 50%;
}
.custom_timer circle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.launchpad_token_section {
  background: #eef2f7;
  padding: 0 0 60px;
}
.timer_panel {
  text-align: center;
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  font-size: 14px;
  color: #7d7d7d;
}
span.timer_time {
  font-size: 20px;
  color: #000;
}
.timer_panel > span {
  margin: 5px;
}
span.timer_dots {
  margin: 0 3px;
  line-height: 38px;
  color: #333333;
}
.launchpad_btn_center {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}
.launchpad_btn_center .btnLandingJoin {
  padding: 10px 30px;
  font-size: 14px;
}
.launchpad_token_single {
  background: #fff;
  border: 1px solid #ebeff4;
  border-radius: 10px;
  box-shadow: 0 0 10px 8px rgb(241 247 253 / 80%);
  margin-bottom: 30px;
}
.launchpad_token_single img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.launchpad_token_single p {
  padding: 0 15px;
  margin: 0 0 10px;
  font-size: 14px;
  color: #000;
}
.launchpad_token_single h4 {
  font-size: 18px;
  color: #000;
  margin: 15px 0 5px;
}
.launchpad_token_single .btnLandingJoin {
  font-size: 14px;
  padding: 7px 30px;
  margin-bottom: 20px;
}
.launchpad_token_single > div p:first-child {
  color: #7d7d7d;
}
.launchpad_token_single h6 {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteBoxLaunchpad {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 10%);
}
.launchpadCoinName {
  display: flex;
  align-items: center;
}
.launchpadCoinName img {
  margin-right: 15px;
}
.launchpadCoinName h3 {
  font-size: 36px;
  font-weight: 400;
}
.launchpadCoinName h3 small {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
}
.launchPadCoinDetails h4 {
  font-size: 18px;
  color: #000;
  border: 2px solid #fcb305;
  padding: 10px 30px;
  border-radius: 50px;
}
.launchPadCoinDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.whiteShadowBox {
  box-shadow: 0 0 4px 10px rgb(231 237 243 / 50%);
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 30px;
}
.projectIntroduction h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
}
.projectIntroduction ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.projectIntroduction li {
  color: #000;
  font-size: 14px;
  margin: 10px 0;
}
.projectIntroduction li span {
  font-size: 14px;
  color: #7d7d7d;
  width: 40%;
  display: inline-block;
}
.projectIntroduction p {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteShadowBox .form-group label {
  color: #000;
  font-size: 16px;
}
.choose_coin_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 10px;
}
.choose_coin_list li {
  margin: 0 25px 0 0;
}
.choose_coin_list li a {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s all;
}
.choose_coin_list li a.active {
  color: #03005b;
  border-bottom: 2px solid #fcb305;
}
.whiteShadowBox input.form-control {
  border: 1px solid #e1e1e1;
}
.whiteShadowBox span.input-group-text {
  background-color: transparent;
  border: 1px solid #e1e1e1;
}
.btnGroupPercent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.whiteShadowBox .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #fec003;
  background-color: #febf04;
}
.whiteShadowBox h3 {
  font-size: 18px;
}
.whiteShadowBox .table thead th {
  border-bottom: 0;
}
.whiteShadowBox .table tbody {
border-top: 0;
}
.project_docs a {
  font-size: 16px;
  color: #7d7d7d;
  margin: 0 40px 0 0;
  display: inline-block;
}
.project_docs a.active {
  color: #fec003;
}
.socialMediaCoinDetails ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.socialMediaCoinDetails ul li {
  margin: 0 20px 0 0;
}
.socialMediaCoinDetails ul li a {
  color: #000;
  border: 1px solid #000;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.4s all;
}
.socialMediaCoinDetails ul li a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.socialMediaCoinDetails h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin-top: 30px;
}
.cdTeamDetail h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin: 30px 0;
}
.cdTeamDetail ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0;
}
.cdTeamProfilePic img {
  border-radius: 50%;
  margin-right: 10px;
}
.cdTeamDetail ul li {
  display: flex;
}
.cdTeamProfileDetail h6 {
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail p {
  color: #7d7d7d;
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail a {
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}
.cdTeamProfileDetail a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.currency_select {
  background: #fff url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
  border: 0;
  box-shadow: none !important;
  font-size: 16px;
  color: #646e88;
  font-weight: 600;
}
.chart_btns button {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  color: #646e88;
  font-weight: 600;
}
.chart_btns {
  background: #f1f2f9;
  padding: 5px;
}
.chart_btns .MuiButton-root:hover{  
  background: #faa400 !important;
  color: #fff;
}
.chart_btns button:nth-child(2) {
  margin: 0 5px;
}
.chart_btns button.active {
  background: #faa400;
  color: #fff; 
}
.exchange_value {
  display: flex;
  align-items: center;
}
.exchange_value h2 {
  color: #292f45;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.exchange_value p {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0 10px;
}
.exchange_div {
  position: relative;
  z-index: 10;
  width: 100%;
}
.trade_wrap_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.trade_wrap_title h2 {
  font-size: 28px;
  color: #292f45;
  font-weight: 700;
  margin: 0;
}
.trade_wrap_title h5 {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
}
button.round_btn {
  background-color: #f1f2f9;
  color: #292f45;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 0;
  min-width: 40px;
  margin-left: 8px;
  transition: 0.4s all;
  font-size: 18px;
  font-weight: 700 !important;
}
button.round_btn .fas{
  font-weight: 600;
}
button.round_btn a {
  color: #a1a1a1;
}
button.round_btn a:hover,
button.round_btn:hover a {
  color: #fff;
}
button.round_btn svg {
  font-size: 30px;
}
button.round_btn:hover {  
  background-color: #f7a100;
  color: #fff;
}
button.round_btn:hover img {
  filter: invert(1) sepia(1);
}
.input_panel {
  background-color: #e9ecf4;
  border-radius: 10px;
  padding: 12px 15px 10px;
  position: relative;
}
input.custom_inp {
  border: 0;
  font-size: 15px;
  font-weight: 600;
  color: #283045;
  background-color: #e9ecf4;
}
.input_panel label {
  font-size: 15px;
  font-weight: 500;
  color: #657191;
}
.input_panel label span {
  color: #292f45;
  font-size: 15px;
  font-weight: 700;
}
.equivalent_value {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32a8df;
  font-size: 15px;
}
.equivalent_value p {
  margin: 0 10px 0 0;
  font-size: 15px;
  font-weight: 600;
  color: #646e88;
}
button.token_btn {
  font-size: 14px;
  font-weight: 500;
  color: #283045;
  text-transform: capitalize;
}
button.token_btn img {
  margin-right: 5px;
  width: 25px;
}
button.token_btn i {
  margin-left: 8px;
}
.trade_right_img {
  margin-top: 180px;
}
button.primary_btn.blue_btn {
  background-color: #32a8df;
  border: 2px solid #32a8df;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: unset;
  width: 100%;
}
button.primary_btn.blue_btn:hover {
  color: #ffffff;
  background-color: #f7a100;
  border: 2px solid #ffd800;
}
input.search_inp {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 10px;
}
.select_token_list {
  list-style: none;
  padding: 0;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}
.select_token_list li {
  display: flex;
  border-top: 2px dashed #ededed;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
}
.select_token_list li img {
  width: 25px;
}
.select_token_list li p {
  margin: 0 8px;
  font-size: 16px;
  color: #333;
}
button.primary_btn a {
  color: #fff;
}
button.primary_btn a:hover,
button.primary_btn:hover a {
  color: #32a8df;
}
.trade_notes > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade_notes > div span {
  font-size: 14px;
  font-weight: 600;
  color: #646e88;
}
.trade_notes > div span:nth-child(2) {
  font-weight: 700;
  color: #292f45;
}
.history_table{
  background-color: green;
}
.history_table_div h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background: #2976a9;
  margin-top: -35px;
  padding: 10px 5px 10px 30px;
  border-radius: 5px;
  margin: 0;
  box-shadow: 0px 0px 1px 0px black;
  margin-top: -40px;
}

.rdt_TableCol_Sortable {
  color: #292f45;
  font-size: 16px;  
}
.rdt_TableCol_Sortable div {
  font-weight: 600 !important;
}
.rdt_TableCell {
  color: #646e88;
  font-size: 14px;
  font-weight: 500 !important;
}
.table_crypto_icon {
  width: 26px;
  margin-right: 8px;
}
.rdt_TableCell i {
  color: #868ea2;
}
.footer_about {
  font-size: 13px;
  color: #8e8cce;
}
.top_menu {
  top: 20px;
  left: 20px;
}
.sticky_left_nav {
  position: fixed;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  background-image: linear-gradient(-300deg, #3a3480, #1d4e84);
  height: 95vh;
  border-radius: 10px;
}
.stick_left_nav_ul li {
  padding: 5px 10px;
  float: none;
}
.stick_left_nav_ul .top_nav_login {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 3px;
  transition: 0.2s all;
}
.stick_left_nav_ul .top_nav_login img {
  width: 20px;
  transition: 0.2s all;
}

.stick_left_nav_ul .top_nav_login:hover::before, .stick_left_nav_ul .top_nav_login.active::before {
  content: "";
  position: absolute;
  height: 50%;
  background: #f7a100;
  left: 0px;
  width: 3px;
}
.stick_left_nav_ul li .top_nav_login:hover img,
.stick_left_nav_ul li .top_nav_login.active img {
  filter: invert(16%) sepia(34%) saturate(7404%) hue-rotate(4deg) brightness(98%) contrast(111%);
  /* background: url("../src/assets/images/homehvr.png") no-repeat; */
}
.brand_logo_mobile {
  display: none;
}
img.brand_desk_logo {
  margin: 10px 0 0;
  padding: 0 8px;
}
.rdt_TableRow {
  cursor: default;
}
.primary_modal .modal-header {
  padding-top: 10px !important;
}
.primary_modal .modal-body {
  padding-top: 5px;
}
.tooltip_content {
  font-size: 14px !important;
}
.tooltip_icon {
  color: #32a8df;
  font-size: 18px !important;
  margin-left: 8px;
}
.wallet_panel_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wallet_panel {
  background-color: #f1f9fd;
  border: 1px solid #d0e9f5;
  padding: 15px 5px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  text-align: center;
  width: 28%;
  transition: 0.2s all;
}
.wallet_panel:hover {
  background-color: #32a8df;
}
.wallet_panel img {
  width: 40px;
  margin-bottom: 10px;
}
.wallet_panel h2 {
  color: #32a8df;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.wallet_panel:hover h2 {
  color: #fff;
}
.wallet_modal_footer a {
  display: flex;
  align-items: center;
}
.wallet_modal_footer span {
  font-size: 16px;
  font-weight: 600;
  color: #32a8df;
}
.liquidity_div {
  position: relative;
  z-index: 10;
}

.liquidity_table h2 {
  font-size: 28px;
  font-weight: 700;
  color: #292f45;
  margin: 0 0 10px;
}
.liquidity_table p {
  color: #646e88;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
.pools_filter {
  background-image: linear-gradient(90deg, #3a3480, #1d4e84);
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.pools_filter_left {
  display: flex;
  align-items: center;
}
.pools_filter_right {
  display: flex;
}
.pools_filter_left .custom-checkbox .custom-control-label::before {
  border: 2px solid #32a8df;
  background: #3f396b;
  box-shadow: none !important;
}
.pools_filter_left .custom-checkbox label {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
.pools_filter_left .custom-checkbox {
  margin-top: 10px;
}
.pools_filter_left .custom-control-label::before {
  top: -2px;
  left: -25px;
}
.pools_filter_left .custom-control-label::after {
  top: -2px;
  left: -25px;
}


.pool_btn_grp {
  background: #3a4a7b;
  border-radius: 10px;
  padding: 8px;
  margin-left: 20px;
}
.pool_btn_grp button {
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  text-transform: unset;
}
.pool_btn_grp button.active {
  background: #32a8df;
  color: #fff;
  border-radius: 10px;
}
.pool_btn_grp button:nth-child(1) {
  margin-right: 8px;
}
.pools_filter_right .custom-select {
  background: #2e72a0 url("../src/assets/images/icon_caret_down.png") no-repeat right 0.75rem center;
  border: 1px solid #2e72a0;
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  box-shadow: none !important;
  margin: 0 15px;
  border-radius: 10px;
}
.pool_search::placeholder {
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
}
.pool_search {
  background: #2e72a0;
  border: 1px solid #2e72a0;
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding-left: 10px;
}
.view_btn_grp {
  display: flex;
  margin-left: 15px;
}
.view_btn_grp button {
  background-color: #2983b0;
  font-size: 24px;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  min-width: inherit;
}
.view_btn_grp button.active,
.view_btn_grp button:hover {
  background-color: #32a8df;
}
.view_btn_grp button:nth-child(1) {
  margin-right: 10px;
}
.grid_view_single {
  background: #fff;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 0 15px;
}
.grid_view_single_first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.grid_view_single_first h2 {
  color: #283044;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.grid_view_single_second {
  padding: 0 15px;
}
.grid_view_single_second p {
  color: #283044;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.grid_view_single_second label {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
} 
.grid_view_single_second button.primary_btn.blue_btn {
  font-size: 15px;
  font-weight: 600;
}
.grid_view_single_second i {
  margin-left: 8px;
  cursor: pointer;
  color: #3d3d3d;
  font-size: 15px;
}
.grid_view_single_third {
  padding: 0 15px;
}
.accordian_link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  background-color: #32a8df;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
}
.accordian_link.collapsed:after {
  content: "\f077";
  background-color: #eaf6fc;
  color: #32a8df;
}
button.manual_btn {
  font-size: 12px;
  font-weight: 700;
  color: #32a8df;
  text-transform: unset;
  background-color: transparent;
  border: 1px solid #32a8df;
  padding: 4px 10px;
}
.accordian_link {
  color: #32a8df !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}
.grid_view_single_third h3 {
  color: #646e88;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.grid_view_single_third h4 {
  color: #283044;
  font-size: 15px;
  font-weight: 700;
}
.grid_view_single_third a {
  color: #32a8df;
  font-size: 14px;
  font-weight: 600;
}
.grid_view_single_third a:hover {
  text-decoration: underline;
}
.grid_view_single_third a i::before {
  font-weight: 600 !important;
  margin-left: 3px;
}
.roi_table.table thead th {
  border: 0;
}
.roi_table.table tbody td,
.roi_table.table thead th {
  padding: 5px 10px;
  font-size: 14px;
}
p.roi_notes {
  font-size: 14px;
  line-height: 24px;
  color: #464646;
}
.list_view_single_first {
  display: flex;
  align-items: center;
}
.list_view_single_first h2 {
  font-size: 14px;
  color: #283044;
  font-weight: 700;
  margin: 0;
}
.list_view_single_first p {
  font-size: 13px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
  line-height: 16px;
}
.list_view_single_first img {
  width: 40px;
  margin-right: 5px;
}
.list_view_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_view_single_second {
  display: flex;
}
.list_view_single_second h3 {
  color: #646e88;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 5px;
}
.list_view_single_second h4 {
  color: #292f45;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.list_view_single_second > div {
  margin: 0 30px;
}
.list_view_single_second p {
  color: #292f45;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.list_view_single_second i {
  margin-left: 5px;
}
.list_view .card-body {
  background-color: #f1f2f9;
}
.list_view_single_card_body {
  display: flex;
  justify-content: space-between;
}
.list_view_single_card_body_right {
  display: flex;
}
.list_view_single_card_body_right .input_panel,
.list_view_single_card_body_right input.custom_inp {
  background-color: #fff;
}
.list_view_start_stake {
  background: #fff;
  padding: 15px;
  margin-left: 20px;
  border-radius: 10px;
  width: 250px;
}
.list_view_start_stake button.primary_btn.blue_btn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.list_view_start_stake button.primary_btn.blue_btn:hover {
  color: #fff;
}
.list_view_start_stake h4 {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
}
.list_view_single_card_body_left a {
  font-size: 14px;
  font-weight: 700;
}
.list_view_single_card_body_left p {
  margin: 0;
}
.list_view_single_card_body_left a i::before {
  font-weight: 600 !important;
}
.list_view .card {
  border-bottom: 1px solid rgb(32 41 60 / 10%) !important;
}
.list_view .card-header {
  cursor: default;
}
.list_view {
  margin: 30px 0;
  display: none;
}
button.harvest_btn {
  color: #32a8df;
  font-size: 15px;
  text-transform: capitalize;
  background: #c0e2f2;
  border: 2px solid #c0e2f2;
  transition: 0.4s all;
  padding: 3px 18px;
  border-radius: 10px;
}
button.harvest_btn.disabled_btn:hover {
  background: #c0e2f2;
  border: 2px solid #c0e2f2;
}
button.harvest_btn:hover {
  background: transparent;
  border: 2px solid #c0e2f2;
}
button.disabled_btn {
  cursor: not-allowed !important;
}
.grid_view_single_second h4 {
  margin: 0 0 5px;
}
.referral_box h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0;
}
.referral_inp {
  background-color: #eff0f9;
  border: 1px solid #eff0f9;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 500;
  color: #32a8df;
  margin-top: 10px;
}
button.referral_btn {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #32a8df;
  border: 1px solid #32a8df;
  margin-top: 15px;
  padding: 7px ​15px;
}
button.referral_btn i::before {
  font-weight: 600;
  font-size: 14px;
  margin-left: 4px;
}
button.referral_btn:hover {
  background-color: transparent;
  border: 1px solid #32a8df;
  color: #32a8df;
}
.total_referral {
  color: #32a8df;
  font-size: 45px;
  font-weight: 700;
  margin: 5px 0 7px;
}
.referral_level_table td img {
  width: 24px;
  margin-right: 10px;
}
.referral_level_table tr td {
  font-size: 15px;
  font-weight: 600;
  color: rgb(0 0 0 / 50%);
  padding: 17px 0;
}
.referral_level_table tr td:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.top_level {
  color: #000;
}
.snap_nav {
  border-top: 1px solid #e9ecf5;
  margin: 20px 0 0;
  display: block;
  min-width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
}
.snap_nav .nav-tabs {
  padding: 0;
  justify-content: space-between;
  margin-top: 10px;
  display: inline-block;
  min-width: 100%;
  white-space: nowrap;
}
.snap_nav a {
  padding: 0 12px !important;
  display: inline-block;
}
.snap_nav a h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0;
}
.snap_nav a h4 span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: rgb(0 0 0 / 80%);
  margin: 0;
}
.snap_nav a.nav-link.active h4 {
  color: #32a8df;
}
.referral_box_select {
  background: #e9ecf5 url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
  font-size: 14px;
  font-weight: 500;
  width: 250px;
  color: #646e88;
}
.referral_box h6 {
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  text-transform: unset;
}
.referral_steps h2 {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  padding: 0 200px;
}
.referral_steps_single {
  text-align: center;
  width: 220px;
  margin-bottom: 30px;
}
.referral_steps_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
ul.share_ref_list {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
ul.share_ref_list li a {
  width: 35px;
  height: 35px;
  background: #32a8df;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 10px 0 0;
  color: #fff;
}
.referral_steps_single h3 {
  color: #292f45;
  font-size: 20px;
  font-weight: 700;
}
.referral_steps_single p {
  color: #646e88;
  font-size: 16px;
  font-weight: 500;
}
.referral_bottom {
  /* background: url("../src/assets/images/referral_bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0 30px;
}
.referral_bottom_single {
  background: #fff;
  padding: 20px 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.referral_bottom_single h2 {
  font-size: 18px;
  font-weight: 700;
  color: #292f45;
  margin: 30px 0 10px;
}
.referral_bottom_single p {
  font-size: 15px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
  min-height: 80px;
}
#success_modal h2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
}
#success_modal p {
  font-size: 15px;
  color: #666666;
}
.swap_coin_info {
  position: relative;
}
.swap_coin_info i {
  position: absolute;
  color: #b0b7cb;
  font-size: 18px;
  top: 56px;
  left: 5px;
}
.swap_coin_info img {
  width: 35px;
}
.swap_coin_info div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecf4;
  padding: 20px 0;
}
.swap_coin_info div span {
  color: #283045;
  font-size: 18px;
  font-weight: 700;
}
.swap_coin_info div span:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.swap_info_mid {
  padding: 15px 0;
}
.swap_info_mid img {
  width: 25px;
}
.swap_info_mid p {
  color: #646e88;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}
.swap_info_mid div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecf4;
  padding: 25px 0 15px;
}
.swap_info_mid div span {
  color: #646e88;
  font-size: 16px;
  font-weight: 600;
}
.swap_info_mid div span:nth-child(2) {
  font-size: 18px;
  color: #283045;
  font-weight: 700;
}
.swap_info_end {
  padding-bottom: 30px;
}
.swap_info_end > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.swap_info_end > div span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #646e88;
}
.swap_info_end > div div {
  text-align: right;
}
.swap_info_end > div div span {
  color: #283045;
  font-size: 16px;
  font-weight: 500;
}
#confirm_swap_modal .swap_coin_info img {
  width: 25px;
}
#confirm_swap_modal .swap_coin_info div span {
  font-size: 20px;
}
#confirm_swap_modal .swap_info_mid p {
  font-style: normal;
}
#confirm_swap_modal .swap_info_mid p span {
  font-weight: 700;
  color: #283045;
}
#confirm_swap_modal .swap_info_end {
  background: #f5f6fa;
  padding: 15px 10px 20px;
  border-bottom: 1px solid #e9ecf4;
  border-top: 1px solid #e9ecf4;
  margin-bottom: 20px;
}
#confirm_swap_modal .swap_info_end > div span {
  font-size: 15px;
}
#confirm_swap_modal .swap_info_end > div span:nth-child(2) {
  color: #283045;
  font-weight: 600;
}
.chart_container {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 360px;
}
.chart_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22%;
  right: 20%;
  bottom: 20%;
  left: 20%;
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 46px;
  z-index: 1; /* Allow tooltips on top */
  overflow: hidden;
}
.chart_status {
  font-size: 50px;
  font-weight: 700;
  color: #283045;
  text-align: center;
}
.chart_status span {
  display: block;
  color: #657191;
  font-size: 24px;
  font-weight: 500;
}
.chart_data h2 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
.chart_data ul {
  padding-left: 30px;
}
.chart_data ul li {
  color: #657191;
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.chart_data ul li span:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  color: #171b1e;
}
.chart_data ul li::before {
  content: "";
  background: #f7931a;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: -30px;
}
.chart_data ul li:nth-child(2):before {
  background: #627eea;
}
.chart_data ul li:nth-child(3):before {
  background: #42c1ca;
}
.chart_data ul li:nth-child(4):before {
  background: #c3a634;
}
.chart_data ul li:nth-child(5):before {
  background: #ef0027;
}
button.buy_coin_btn {
  color: #32a8df;
  font-size: 18px;
  font-weight: 700;
  background: #ffffff;
  border-radius: 5px;
  text-transform: unset;
  border: 2px solid #fff;
  padding: 6px 20px;
}
button.buy_coin_btn:hover {
  color: #fff;
  background: transparent;
}
.starts_section h2 {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.starts_section p {
  color:#fff;
}
.starts_section .para {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
} 
.banner_ticker_panel .slick-prev {
  left: -50px;
  /* background: url("../src/assets/images/leftarw.png") !important; */
}
.banner_ticker_panel .slick-next {
  right: -50px;
  /* background: url("../src/assets/images/rightarwhvr.png") !important; */
}
.banner_ticker_panel .slick-prev, 
.banner_ticker_panel .slick-next {
  width: 48px;
  height: 48px;
  transition: all 0.2s;
}
/* .banner_ticker_panel .slick-prev:hover {  
  background: url("../src/assets/images/leftarwhvr.png") !important;
}
.banner_ticker_panel .slick-next:hover {
  background: url("../src/assets/images/rightarw.png") !important;

} */
.banner_ticker_panel .slick-prev:hover, 
.banner_ticker_panel .slick-next:hover {
  filter: drop-shadow(2px 4px 3px rgb(0 0 0 / 50%));
}
.banner_ticker_panel .slick-prev::before,
.banner_ticker_panel .slick-next::before {
  content: "";
}
.page-header canvas {
  position: fixed;
  top: 0;
}
.MuiSlider-root {
  color: #32a8df !important;
}
.circle-container {
  position: absolute;
  transform: translateY(-10vh);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#99ffff, #99ffff 10%, rgba(153, 255, 255, 0) 56%);
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
          animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circle-container:nth-child(1) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-1;
          animation-name: move-frames-1;
  -webkit-animation-duration: 29641ms;
          animation-duration: 29641ms;
  -webkit-animation-delay: 14573ms;
          animation-delay: 14573ms;
}
@-webkit-keyframes move-frames-1 {
  from {
    transform: translate3d(47vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -110vh, 0);
  }
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(47vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -110vh, 0);
  }
}
.circle-container:nth-child(1) .circle {
  -webkit-animation-delay: 2235ms;
          animation-delay: 2235ms;
}
.circle-container:nth-child(2) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-2;
          animation-name: move-frames-2;
  -webkit-animation-duration: 31753ms;
          animation-duration: 31753ms;
  -webkit-animation-delay: 9730ms;
          animation-delay: 9730ms;
}
@-webkit-keyframes move-frames-2 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -116vh, 0);
  }
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -116vh, 0);
  }
}
.circle-container:nth-child(2) .circle {
  -webkit-animation-delay: 1421ms;
          animation-delay: 1421ms;
}
.circle-container:nth-child(3) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-3;
          animation-name: move-frames-3;
  -webkit-animation-duration: 33224ms;
          animation-duration: 33224ms;
  -webkit-animation-delay: 14986ms;
          animation-delay: 14986ms;
}
@-webkit-keyframes move-frames-3 {
  from {
    transform: translate3d(19vw, 105vh, 0);
  }
  to {
    transform: translate3d(53vw, -127vh, 0);
  }
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(19vw, 105vh, 0);
  }
  to {
    transform: translate3d(53vw, -127vh, 0);
  }
}
.circle-container:nth-child(3) .circle {
  -webkit-animation-delay: 402ms;
          animation-delay: 402ms;
}
.circle-container:nth-child(4) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-4;
          animation-name: move-frames-4;
  -webkit-animation-duration: 31890ms;
          animation-duration: 31890ms;
  -webkit-animation-delay: 17711ms;
          animation-delay: 17711ms;
}
@-webkit-keyframes move-frames-4 {
  from {
    transform: translate3d(89vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -112vh, 0);
  }
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(89vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -112vh, 0);
  }
}
.circle-container:nth-child(4) .circle {
  -webkit-animation-delay: 779ms;
          animation-delay: 779ms;
}
.circle-container:nth-child(5) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-5;
          animation-name: move-frames-5;
  -webkit-animation-duration: 33732ms;
          animation-duration: 33732ms;
  -webkit-animation-delay: 15614ms;
          animation-delay: 15614ms;
}
@-webkit-keyframes move-frames-5 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(10vw, -111vh, 0);
  }
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(10vw, -111vh, 0);
  }
}
.circle-container:nth-child(5) .circle {
  -webkit-animation-delay: 525ms;
          animation-delay: 525ms;
}
.circle-container:nth-child(6) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-6;
          animation-name: move-frames-6;
  -webkit-animation-duration: 36334ms;
          animation-duration: 36334ms;
  -webkit-animation-delay: 5482ms;
          animation-delay: 5482ms;
}
@-webkit-keyframes move-frames-6 {
  from {
    transform: translate3d(87vw, 104vh, 0);
  }
  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(87vw, 104vh, 0);
  }
  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}
.circle-container:nth-child(6) .circle {
  -webkit-animation-delay: 940ms;
          animation-delay: 940ms;
}
.circle-container:nth-child(7) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-7;
          animation-name: move-frames-7;
  -webkit-animation-duration: 30572ms;
          animation-duration: 30572ms;
  -webkit-animation-delay: 34728ms;
          animation-delay: 34728ms;
}
@-webkit-keyframes move-frames-7 {
  from {
    transform: translate3d(88vw, 107vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(88vw, 107vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
.circle-container:nth-child(7) .circle {
  -webkit-animation-delay: 2903ms;
          animation-delay: 2903ms;
}
.circle-container:nth-child(8) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-8;
          animation-name: move-frames-8;
  -webkit-animation-duration: 31938ms;
          animation-duration: 31938ms;
  -webkit-animation-delay: 21055ms;
          animation-delay: 21055ms;
}
@-webkit-keyframes move-frames-8 {
  from {
    transform: translate3d(91vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -111vh, 0);
  }
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(91vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -111vh, 0);
  }
}
.circle-container:nth-child(8) .circle {
  -webkit-animation-delay: 1146ms;
          animation-delay: 1146ms;
}
.circle-container:nth-child(9) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-9;
          animation-name: move-frames-9;
  -webkit-animation-duration: 30130ms;
          animation-duration: 30130ms;
  -webkit-animation-delay: 31891ms;
          animation-delay: 31891ms;
}
@-webkit-keyframes move-frames-9 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -130vh, 0);
  }
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -130vh, 0);
  }
}
.circle-container:nth-child(9) .circle {
  -webkit-animation-delay: 3840ms;
          animation-delay: 3840ms;
}
.circle-container:nth-child(10) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-10;
          animation-name: move-frames-10;
  -webkit-animation-duration: 31334ms;
          animation-duration: 31334ms;
  -webkit-animation-delay: 5767ms;
          animation-delay: 5767ms;
}
@-webkit-keyframes move-frames-10 {
  from {
    transform: translate3d(84vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -121vh, 0);
  }
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(84vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -121vh, 0);
  }
}
.circle-container:nth-child(10) .circle {
  -webkit-animation-delay: 2223ms;
          animation-delay: 2223ms;
}
.circle-container:nth-child(11) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-11;
          animation-name: move-frames-11;
  -webkit-animation-duration: 30243ms;
          animation-duration: 30243ms;
  -webkit-animation-delay: 34516ms;
          animation-delay: 34516ms;
}
@-webkit-keyframes move-frames-11 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(33vw, -107vh, 0);
  }
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(33vw, -107vh, 0);
  }
}
.circle-container:nth-child(11) .circle {
  -webkit-animation-delay: 3537ms;
          animation-delay: 3537ms;
}
.circle-container:nth-child(12) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-12;
          animation-name: move-frames-12;
  -webkit-animation-duration: 30514ms;
          animation-duration: 30514ms;
  -webkit-animation-delay: 31646ms;
          animation-delay: 31646ms;
}
@-webkit-keyframes move-frames-12 {
  from {
    transform: translate3d(26vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -105vh, 0);
  }
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(26vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -105vh, 0);
  }
}
.circle-container:nth-child(12) .circle {
  -webkit-animation-delay: 632ms;
          animation-delay: 632ms;
}
.circle-container:nth-child(13) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-13;
          animation-name: move-frames-13;
  -webkit-animation-duration: 30350ms;
          animation-duration: 30350ms;
  -webkit-animation-delay: 28598ms;
          animation-delay: 28598ms;
}
@-webkit-keyframes move-frames-13 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -129vh, 0);
  }
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -129vh, 0);
  }
}
.circle-container:nth-child(13) .circle {
  -webkit-animation-delay: 3688ms;
          animation-delay: 3688ms;
}
.circle-container:nth-child(14) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-14;
          animation-name: move-frames-14;
  -webkit-animation-duration: 30550ms;
          animation-duration: 30550ms;
  -webkit-animation-delay: 24563ms;
          animation-delay: 24563ms;
}
@-webkit-keyframes move-frames-14 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -135vh, 0);
  }
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -135vh, 0);
  }
}
.circle-container:nth-child(14) .circle {
  -webkit-animation-delay: 785ms;
          animation-delay: 785ms;
}
.circle-container:nth-child(15) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-15;
          animation-name: move-frames-15;
  -webkit-animation-duration: 33035ms;
          animation-duration: 33035ms;
  -webkit-animation-delay: 2880ms;
          animation-delay: 2880ms;
}
@-webkit-keyframes move-frames-15 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(33vw, -126vh, 0);
  }
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(33vw, -126vh, 0);
  }
}
.circle-container:nth-child(15) .circle {
  -webkit-animation-delay: 2438ms;
          animation-delay: 2438ms;
}
.circle-container:nth-child(16) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-16;
          animation-name: move-frames-16;
  -webkit-animation-duration: 30272ms;
          animation-duration: 30272ms;
  -webkit-animation-delay: 12724ms;
          animation-delay: 12724ms;
}
@-webkit-keyframes move-frames-16 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(34vw, -122vh, 0);
  }
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(34vw, -122vh, 0);
  }
}
.circle-container:nth-child(16) .circle {
  -webkit-animation-delay: 1861ms;
          animation-delay: 1861ms;
}
.circle-container:nth-child(17) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-17;
          animation-name: move-frames-17;
  -webkit-animation-duration: 28340ms;
          animation-duration: 28340ms;
  -webkit-animation-delay: 13363ms;
          animation-delay: 13363ms;
}
@-webkit-keyframes move-frames-17 {
  from {
    transform: translate3d(8vw, 107vh, 0);
  }
  to {
    transform: translate3d(90vw, -124vh, 0);
  }
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(8vw, 107vh, 0);
  }
  to {
    transform: translate3d(90vw, -124vh, 0);
  }
}
.circle-container:nth-child(17) .circle {
  -webkit-animation-delay: 2386ms;
          animation-delay: 2386ms;
}
.circle-container:nth-child(18) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-18;
          animation-name: move-frames-18;
  -webkit-animation-duration: 36271ms;
          animation-duration: 36271ms;
  -webkit-animation-delay: 20631ms;
          animation-delay: 20631ms;
}
@-webkit-keyframes move-frames-18 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -121vh, 0);
  }
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -121vh, 0);
  }
}
.circle-container:nth-child(18) .circle {
  -webkit-animation-delay: 3878ms;
          animation-delay: 3878ms;
}
.circle-container:nth-child(19) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-19;
          animation-name: move-frames-19;
  -webkit-animation-duration: 30137ms;
          animation-duration: 30137ms;
  -webkit-animation-delay: 14240ms;
          animation-delay: 14240ms;
}
@-webkit-keyframes move-frames-19 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -109vh, 0);
  }
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -109vh, 0);
  }
}
.circle-container:nth-child(19) .circle {
  -webkit-animation-delay: 846ms;
          animation-delay: 846ms;
}
.circle-container:nth-child(20) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-20;
          animation-name: move-frames-20;
  -webkit-animation-duration: 36666ms;
          animation-duration: 36666ms;
  -webkit-animation-delay: 30301ms;
          animation-delay: 30301ms;
}
@-webkit-keyframes move-frames-20 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -133vh, 0);
  }
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -133vh, 0);
  }
}
.circle-container:nth-child(20) .circle {
  -webkit-animation-delay: 74ms;
          animation-delay: 74ms;
}
.circle-container:nth-child(21) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-21;
          animation-name: move-frames-21;
  -webkit-animation-duration: 31487ms;
          animation-duration: 31487ms;
  -webkit-animation-delay: 18754ms;
          animation-delay: 18754ms;
}
@-webkit-keyframes move-frames-21 {
  from {
    transform: translate3d(60vw, 106vh, 0);
  }
  to {
    transform: translate3d(25vw, -127vh, 0);
  }
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(60vw, 106vh, 0);
  }
  to {
    transform: translate3d(25vw, -127vh, 0);
  }
}
.circle-container:nth-child(21) .circle {
  -webkit-animation-delay: 970ms;
          animation-delay: 970ms;
}
.circle-container:nth-child(22) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-22;
          animation-name: move-frames-22;
  -webkit-animation-duration: 29674ms;
          animation-duration: 29674ms;
  -webkit-animation-delay: 28872ms;
          animation-delay: 28872ms;
}
@-webkit-keyframes move-frames-22 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -134vh, 0);
  }
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -134vh, 0);
  }
}
.circle-container:nth-child(22) .circle {
  -webkit-animation-delay: 3941ms;
          animation-delay: 3941ms;
}
.circle-container:nth-child(23) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-23;
          animation-name: move-frames-23;
  -webkit-animation-duration: 36609ms;
          animation-duration: 36609ms;
  -webkit-animation-delay: 26759ms;
          animation-delay: 26759ms;
}
@-webkit-keyframes move-frames-23 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -122vh, 0);
  }
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -122vh, 0);
  }
}
.circle-container:nth-child(23) .circle {
  -webkit-animation-delay: 3476ms;
          animation-delay: 3476ms;
}
.circle-container:nth-child(24) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-24;
          animation-name: move-frames-24;
  -webkit-animation-duration: 28289ms;
          animation-duration: 28289ms;
  -webkit-animation-delay: 28531ms;
          animation-delay: 28531ms;
}
@-webkit-keyframes move-frames-24 {
  from {
    transform: translate3d(44vw, 107vh, 0);
  }
  to {
    transform: translate3d(84vw, -125vh, 0);
  }
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(44vw, 107vh, 0);
  }
  to {
    transform: translate3d(84vw, -125vh, 0);
  }
}
.circle-container:nth-child(24) .circle {
  -webkit-animation-delay: 3929ms;
          animation-delay: 3929ms;
}
.circle-container:nth-child(25) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-25;
          animation-name: move-frames-25;
  -webkit-animation-duration: 30459ms;
          animation-duration: 30459ms;
  -webkit-animation-delay: 565ms;
          animation-delay: 565ms;
}
@-webkit-keyframes move-frames-25 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }
  to {
    transform: translate3d(91vw, -131vh, 0);
  }
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }
  to {
    transform: translate3d(91vw, -131vh, 0);
  }
}
.circle-container:nth-child(25) .circle {
  -webkit-animation-delay: 828ms;
          animation-delay: 828ms;
}
.circle-container:nth-child(26) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-26;
          animation-name: move-frames-26;
  -webkit-animation-duration: 31617ms;
          animation-duration: 31617ms;
  -webkit-animation-delay: 28945ms;
          animation-delay: 28945ms;
}
@-webkit-keyframes move-frames-26 {
  from {
    transform: translate3d(73vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -138vh, 0);
  }
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(73vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -138vh, 0);
  }
}
.circle-container:nth-child(26) .circle {
  -webkit-animation-delay: 2466ms;
          animation-delay: 2466ms;
}
.circle-container:nth-child(27) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-27;
          animation-name: move-frames-27;
  -webkit-animation-duration: 29007ms;
          animation-duration: 29007ms;
  -webkit-animation-delay: 3845ms;
          animation-delay: 3845ms;
}
@-webkit-keyframes move-frames-27 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(49vw, -130vh, 0);
  }
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(49vw, -130vh, 0);
  }
}
.circle-container:nth-child(27) .circle {
  -webkit-animation-delay: 1728ms;
          animation-delay: 1728ms;
}
.circle-container:nth-child(28) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-28;
          animation-name: move-frames-28;
  -webkit-animation-duration: 34859ms;
          animation-duration: 34859ms;
  -webkit-animation-delay: 15988ms;
          animation-delay: 15988ms;
}
@-webkit-keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(56vw, -124vh, 0);
  }
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(56vw, -124vh, 0);
  }
}
.circle-container:nth-child(28) .circle {
  -webkit-animation-delay: 2655ms;
          animation-delay: 2655ms;
}
.circle-container:nth-child(29) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-29;
          animation-name: move-frames-29;
  -webkit-animation-duration: 36616ms;
          animation-duration: 36616ms;
  -webkit-animation-delay: 34537ms;
          animation-delay: 34537ms;
}
@-webkit-keyframes move-frames-29 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -108vh, 0);
  }
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -108vh, 0);
  }
}
.circle-container:nth-child(29) .circle {
  -webkit-animation-delay: 3612ms;
          animation-delay: 3612ms;
}
.circle-container:nth-child(30) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-30;
          animation-name: move-frames-30;
  -webkit-animation-duration: 31308ms;
          animation-duration: 31308ms;
  -webkit-animation-delay: 20547ms;
          animation-delay: 20547ms;
}
@-webkit-keyframes move-frames-30 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -127vh, 0);
  }
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -127vh, 0);
  }
}
.circle-container:nth-child(30) .circle {
  -webkit-animation-delay: 3883ms;
          animation-delay: 3883ms;
}
.circle-container:nth-child(31) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-31;
          animation-name: move-frames-31;
  -webkit-animation-duration: 30460ms;
          animation-duration: 30460ms;
  -webkit-animation-delay: 30386ms;
          animation-delay: 30386ms;
}
@-webkit-keyframes move-frames-31 {
  from {
    transform: translate3d(39vw, 103vh, 0);
  }
  to {
    transform: translate3d(49vw, -110vh, 0);
  }
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(39vw, 103vh, 0);
  }
  to {
    transform: translate3d(49vw, -110vh, 0);
  }
}
.circle-container:nth-child(31) .circle {
  -webkit-animation-delay: 3335ms;
          animation-delay: 3335ms;
}
.circle-container:nth-child(32) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-32;
          animation-name: move-frames-32;
  -webkit-animation-duration: 34707ms;
          animation-duration: 34707ms;
  -webkit-animation-delay: 19470ms;
          animation-delay: 19470ms;
}
@-webkit-keyframes move-frames-32 {
  from {
    transform: translate3d(34vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -111vh, 0);
  }
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(34vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -111vh, 0);
  }
}
.circle-container:nth-child(32) .circle {
  -webkit-animation-delay: 3742ms;
          animation-delay: 3742ms;
}
.circle-container:nth-child(33) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-33;
          animation-name: move-frames-33;
  -webkit-animation-duration: 31189ms;
          animation-duration: 31189ms;
  -webkit-animation-delay: 6131ms;
          animation-delay: 6131ms;
}
@-webkit-keyframes move-frames-33 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
.circle-container:nth-child(33) .circle {
  -webkit-animation-delay: 1957ms;
          animation-delay: 1957ms;
}
.circle-container:nth-child(34) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-34;
          animation-name: move-frames-34;
  -webkit-animation-duration: 28060ms;
          animation-duration: 28060ms;
  -webkit-animation-delay: 35825ms;
          animation-delay: 35825ms;
}
@-webkit-keyframes move-frames-34 {
  from {
    transform: translate3d(83vw, 104vh, 0);
  }
  to {
    transform: translate3d(79vw, -109vh, 0);
  }
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(83vw, 104vh, 0);
  }
  to {
    transform: translate3d(79vw, -109vh, 0);
  }
}
.circle-container:nth-child(34) .circle {
  -webkit-animation-delay: 2126ms;
          animation-delay: 2126ms;
}
.circle-container:nth-child(35) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-35;
          animation-name: move-frames-35;
  -webkit-animation-duration: 28539ms;
          animation-duration: 28539ms;
  -webkit-animation-delay: 32327ms;
          animation-delay: 32327ms;
}
@-webkit-keyframes move-frames-35 {
  from {
    transform: translate3d(46vw, 109vh, 0);
  }
  to {
    transform: translate3d(86vw, -138vh, 0);
  }
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(46vw, 109vh, 0);
  }
  to {
    transform: translate3d(86vw, -138vh, 0);
  }
}
.circle-container:nth-child(35) .circle {
  -webkit-animation-delay: 3469ms;
          animation-delay: 3469ms;
}
.circle-container:nth-child(36) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-36;
          animation-name: move-frames-36;
  -webkit-animation-duration: 28438ms;
          animation-duration: 28438ms;
  -webkit-animation-delay: 27158ms;
          animation-delay: 27158ms;
}
@-webkit-keyframes move-frames-36 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -129vh, 0);
  }
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -129vh, 0);
  }
}
.circle-container:nth-child(36) .circle {
  -webkit-animation-delay: 3119ms;
          animation-delay: 3119ms;
}
.circle-container:nth-child(37) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-37;
          animation-name: move-frames-37;
  -webkit-animation-duration: 35777ms;
          animation-duration: 35777ms;
  -webkit-animation-delay: 27129ms;
          animation-delay: 27129ms;
}
@-webkit-keyframes move-frames-37 {
  from {
    transform: translate3d(76vw, 108vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(76vw, 108vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
.circle-container:nth-child(37) .circle {
  -webkit-animation-delay: 473ms;
          animation-delay: 473ms;
}
.circle-container:nth-child(38) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-38;
          animation-name: move-frames-38;
  -webkit-animation-duration: 33752ms;
          animation-duration: 33752ms;
  -webkit-animation-delay: 20321ms;
          animation-delay: 20321ms;
}
@-webkit-keyframes move-frames-38 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -129vh, 0);
  }
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -129vh, 0);
  }
}
.circle-container:nth-child(38) .circle {
  -webkit-animation-delay: 1607ms;
          animation-delay: 1607ms;
}
.circle-container:nth-child(39) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-39;
          animation-name: move-frames-39;
  -webkit-animation-duration: 34859ms;
          animation-duration: 34859ms;
  -webkit-animation-delay: 1599ms;
          animation-delay: 1599ms;
}
@-webkit-keyframes move-frames-39 {
  from {
    transform: translate3d(32vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -138vh, 0);
  }
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(32vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -138vh, 0);
  }
}
.circle-container:nth-child(39) .circle {
  -webkit-animation-delay: 1744ms;
          animation-delay: 1744ms;
}
.circle-container:nth-child(40) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-40;
          animation-name: move-frames-40;
  -webkit-animation-duration: 33249ms;
          animation-duration: 33249ms;
  -webkit-animation-delay: 21974ms;
          animation-delay: 21974ms;
}
@-webkit-keyframes move-frames-40 {
  from {
    transform: translate3d(82vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(82vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
.circle-container:nth-child(40) .circle {
  -webkit-animation-delay: 3318ms;
          animation-delay: 3318ms;
}
.circle-container:nth-child(41) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-41;
          animation-name: move-frames-41;
  -webkit-animation-duration: 30897ms;
          animation-duration: 30897ms;
  -webkit-animation-delay: 3548ms;
          animation-delay: 3548ms;
}
@-webkit-keyframes move-frames-41 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -117vh, 0);
  }
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -117vh, 0);
  }
}
.circle-container:nth-child(41) .circle {
  -webkit-animation-delay: 967ms;
          animation-delay: 967ms;
}
.circle-container:nth-child(42) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-42;
          animation-name: move-frames-42;
  -webkit-animation-duration: 31198ms;
          animation-duration: 31198ms;
  -webkit-animation-delay: 5054ms;
          animation-delay: 5054ms;
}
@-webkit-keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}
.circle-container:nth-child(42) .circle {
  -webkit-animation-delay: 3703ms;
          animation-delay: 3703ms;
}
.circle-container:nth-child(43) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-43;
          animation-name: move-frames-43;
  -webkit-animation-duration: 35195ms;
          animation-duration: 35195ms;
  -webkit-animation-delay: 33448ms;
          animation-delay: 33448ms;
}
@-webkit-keyframes move-frames-43 {
  from {
    transform: translate3d(46vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -125vh, 0);
  }
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(46vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -125vh, 0);
  }
}
.circle-container:nth-child(43) .circle {
  -webkit-animation-delay: 483ms;
          animation-delay: 483ms;
}
.circle-container:nth-child(44) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-44;
          animation-name: move-frames-44;
  -webkit-animation-duration: 29608ms;
          animation-duration: 29608ms;
  -webkit-animation-delay: 32361ms;
          animation-delay: 32361ms;
}
@-webkit-keyframes move-frames-44 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }
  to {
    transform: translate3d(95vw, -125vh, 0);
  }
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }
  to {
    transform: translate3d(95vw, -125vh, 0);
  }
}
.circle-container:nth-child(44) .circle {
  -webkit-animation-delay: 1020ms;
          animation-delay: 1020ms;
}
.circle-container:nth-child(45) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-45;
          animation-name: move-frames-45;
  -webkit-animation-duration: 28887ms;
          animation-duration: 28887ms;
  -webkit-animation-delay: 8766ms;
          animation-delay: 8766ms;
}
@-webkit-keyframes move-frames-45 {
  from {
    transform: translate3d(31vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -126vh, 0);
  }
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(31vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -126vh, 0);
  }
}
.circle-container:nth-child(45) .circle {
  -webkit-animation-delay: 1525ms;
          animation-delay: 1525ms;
}
.circle-container:nth-child(46) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-46;
          animation-name: move-frames-46;
  -webkit-animation-duration: 29227ms;
          animation-duration: 29227ms;
  -webkit-animation-delay: 20075ms;
          animation-delay: 20075ms;
}
@-webkit-keyframes move-frames-46 {
  from {
    transform: translate3d(76vw, 101vh, 0);
  }
  to {
    transform: translate3d(48vw, -115vh, 0);
  }
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(76vw, 101vh, 0);
  }
  to {
    transform: translate3d(48vw, -115vh, 0);
  }
}
.circle-container:nth-child(46) .circle {
  -webkit-animation-delay: 3350ms;
          animation-delay: 3350ms;
}
.circle-container:nth-child(47) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-47;
          animation-name: move-frames-47;
  -webkit-animation-duration: 30024ms;
          animation-duration: 30024ms;
  -webkit-animation-delay: 16852ms;
          animation-delay: 16852ms;
}
@-webkit-keyframes move-frames-47 {
  from {
    transform: translate3d(86vw, 101vh, 0);
  }
  to {
    transform: translate3d(57vw, -121vh, 0);
  }
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(86vw, 101vh, 0);
  }
  to {
    transform: translate3d(57vw, -121vh, 0);
  }
}
.circle-container:nth-child(47) .circle {
  -webkit-animation-delay: 2018ms;
          animation-delay: 2018ms;
}
.circle-container:nth-child(48) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-48;
          animation-name: move-frames-48;
  -webkit-animation-duration: 36403ms;
          animation-duration: 36403ms;
  -webkit-animation-delay: 5315ms;
          animation-delay: 5315ms;
}
@-webkit-keyframes move-frames-48 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -111vh, 0);
  }
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -111vh, 0);
  }
}
.circle-container:nth-child(48) .circle {
  -webkit-animation-delay: 2978ms;
          animation-delay: 2978ms;
}
.circle-container:nth-child(49) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-49;
          animation-name: move-frames-49;
  -webkit-animation-duration: 35036ms;
          animation-duration: 35036ms;
  -webkit-animation-delay: 32653ms;
          animation-delay: 32653ms;
}
@-webkit-keyframes move-frames-49 {
  from {
    transform: translate3d(84vw, 109vh, 0);
  }
  to {
    transform: translate3d(52vw, -126vh, 0);
  }
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(84vw, 109vh, 0);
  }
  to {
    transform: translate3d(52vw, -126vh, 0);
  }
}
.circle-container:nth-child(49) .circle {
  -webkit-animation-delay: 1739ms;
          animation-delay: 1739ms;
}
.circle-container:nth-child(50) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-50;
          animation-name: move-frames-50;
  -webkit-animation-duration: 31742ms;
          animation-duration: 31742ms;
  -webkit-animation-delay: 23443ms;
          animation-delay: 23443ms;
}
@-webkit-keyframes move-frames-50 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
.circle-container:nth-child(50) .circle {
  -webkit-animation-delay: 1425ms;
          animation-delay: 1425ms;
}
.circle-container:nth-child(51) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-51;
          animation-name: move-frames-51;
  -webkit-animation-duration: 35653ms;
          animation-duration: 35653ms;
  -webkit-animation-delay: 19158ms;
          animation-delay: 19158ms;
}
@-webkit-keyframes move-frames-51 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(79vw, -123vh, 0);
  }
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(79vw, -123vh, 0);
  }
}
.circle-container:nth-child(51) .circle {
  -webkit-animation-delay: 1749ms;
          animation-delay: 1749ms;
}
.circle-container:nth-child(52) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-52;
          animation-name: move-frames-52;
  -webkit-animation-duration: 36544ms;
          animation-duration: 36544ms;
  -webkit-animation-delay: 13666ms;
          animation-delay: 13666ms;
}
@-webkit-keyframes move-frames-52 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(90vw, -132vh, 0);
  }
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(90vw, -132vh, 0);
  }
}
.circle-container:nth-child(52) .circle {
  -webkit-animation-delay: 2447ms;
          animation-delay: 2447ms;
}
.circle-container:nth-child(53) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-53;
          animation-name: move-frames-53;
  -webkit-animation-duration: 30027ms;
          animation-duration: 30027ms;
  -webkit-animation-delay: 30ms;
          animation-delay: 30ms;
}
@-webkit-keyframes move-frames-53 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }
  to {
    transform: translate3d(21vw, -107vh, 0);
  }
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }
  to {
    transform: translate3d(21vw, -107vh, 0);
  }
}
.circle-container:nth-child(53) .circle {
  -webkit-animation-delay: 1030ms;
          animation-delay: 1030ms;
}
.circle-container:nth-child(54) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-54;
          animation-name: move-frames-54;
  -webkit-animation-duration: 33428ms;
          animation-duration: 33428ms;
  -webkit-animation-delay: 23195ms;
          animation-delay: 23195ms;
}
@-webkit-keyframes move-frames-54 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -113vh, 0);
  }
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -113vh, 0);
  }
}
.circle-container:nth-child(54) .circle {
  -webkit-animation-delay: 1903ms;
          animation-delay: 1903ms;
}
.circle-container:nth-child(55) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-55;
          animation-name: move-frames-55;
  -webkit-animation-duration: 33454ms;
          animation-duration: 33454ms;
  -webkit-animation-delay: 34820ms;
          animation-delay: 34820ms;
}
@-webkit-keyframes move-frames-55 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -125vh, 0);
  }
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -125vh, 0);
  }
}
.circle-container:nth-child(55) .circle {
  -webkit-animation-delay: 3080ms;
          animation-delay: 3080ms;
}
.circle-container:nth-child(56) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-56;
          animation-name: move-frames-56;
  -webkit-animation-duration: 28018ms;
          animation-duration: 28018ms;
  -webkit-animation-delay: 6144ms;
          animation-delay: 6144ms;
}
@-webkit-keyframes move-frames-56 {
  from {
    transform: translate3d(33vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -114vh, 0);
  }
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(33vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -114vh, 0);
  }
}
.circle-container:nth-child(56) .circle {
  -webkit-animation-delay: 1469ms;
          animation-delay: 1469ms;
}
.circle-container:nth-child(57) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-57;
          animation-name: move-frames-57;
  -webkit-animation-duration: 34223ms;
          animation-duration: 34223ms;
  -webkit-animation-delay: 2169ms;
          animation-delay: 2169ms;
}
@-webkit-keyframes move-frames-57 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}
.circle-container:nth-child(57) .circle {
  -webkit-animation-delay: 116ms;
          animation-delay: 116ms;
}
.circle-container:nth-child(58) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-58;
          animation-name: move-frames-58;
  -webkit-animation-duration: 29732ms;
          animation-duration: 29732ms;
  -webkit-animation-delay: 10504ms;
          animation-delay: 10504ms;
}
@-webkit-keyframes move-frames-58 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -127vh, 0);
  }
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -127vh, 0);
  }
}
.circle-container:nth-child(58) .circle {
  -webkit-animation-delay: 1863ms;
          animation-delay: 1863ms;
}
.circle-container:nth-child(59) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-59;
          animation-name: move-frames-59;
  -webkit-animation-duration: 33590ms;
          animation-duration: 33590ms;
  -webkit-animation-delay: 21970ms;
          animation-delay: 21970ms;
}
@-webkit-keyframes move-frames-59 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(52vw, -109vh, 0);
  }
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(52vw, -109vh, 0);
  }
}
.circle-container:nth-child(59) .circle {
  -webkit-animation-delay: 401ms;
          animation-delay: 401ms;
}
.circle-container:nth-child(60) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-60;
          animation-name: move-frames-60;
  -webkit-animation-duration: 35829ms;
          animation-duration: 35829ms;
  -webkit-animation-delay: 6034ms;
          animation-delay: 6034ms;
}
@-webkit-keyframes move-frames-60 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
.circle-container:nth-child(60) .circle {
  -webkit-animation-delay: 1928ms;
          animation-delay: 1928ms;
}
.circle-container:nth-child(61) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-61;
          animation-name: move-frames-61;
  -webkit-animation-duration: 31259ms;
          animation-duration: 31259ms;
  -webkit-animation-delay: 3250ms;
          animation-delay: 3250ms;
}
@-webkit-keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}
.circle-container:nth-child(61) .circle {
  -webkit-animation-delay: 885ms;
          animation-delay: 885ms;
}
.circle-container:nth-child(62) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-62;
          animation-name: move-frames-62;
  -webkit-animation-duration: 35053ms;
          animation-duration: 35053ms;
  -webkit-animation-delay: 28707ms;
          animation-delay: 28707ms;
}
@-webkit-keyframes move-frames-62 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(48vw, -132vh, 0);
  }
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(48vw, -132vh, 0);
  }
}
.circle-container:nth-child(62) .circle {
  -webkit-animation-delay: 979ms;
          animation-delay: 979ms;
}
.circle-container:nth-child(63) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-63;
          animation-name: move-frames-63;
  -webkit-animation-duration: 28256ms;
          animation-duration: 28256ms;
  -webkit-animation-delay: 10373ms;
          animation-delay: 10373ms;
}
@-webkit-keyframes move-frames-63 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(50vw, -132vh, 0);
  }
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(50vw, -132vh, 0);
  }
}
.circle-container:nth-child(63) .circle {
  -webkit-animation-delay: 2312ms;
          animation-delay: 2312ms;
}
.circle-container:nth-child(64) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-64;
          animation-name: move-frames-64;
  -webkit-animation-duration: 31529ms;
          animation-duration: 31529ms;
  -webkit-animation-delay: 20486ms;
          animation-delay: 20486ms;
}
@-webkit-keyframes move-frames-64 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -104vh, 0);
  }
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -104vh, 0);
  }
}
.circle-container:nth-child(64) .circle {
  -webkit-animation-delay: 2765ms;
          animation-delay: 2765ms;
}
.circle-container:nth-child(65) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-65;
          animation-name: move-frames-65;
  -webkit-animation-duration: 29896ms;
          animation-duration: 29896ms;
  -webkit-animation-delay: 36773ms;
          animation-delay: 36773ms;
}
@-webkit-keyframes move-frames-65 {
  from {
    transform: translate3d(6vw, 101vh, 0);
  }
  to {
    transform: translate3d(29vw, -106vh, 0);
  }
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(6vw, 101vh, 0);
  }
  to {
    transform: translate3d(29vw, -106vh, 0);
  }
}
.circle-container:nth-child(65) .circle {
  -webkit-animation-delay: 752ms;
          animation-delay: 752ms;
}
.circle-container:nth-child(66) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-66;
          animation-name: move-frames-66;
  -webkit-animation-duration: 35574ms;
          animation-duration: 35574ms;
  -webkit-animation-delay: 12011ms;
          animation-delay: 12011ms;
}
@-webkit-keyframes move-frames-66 {
  from {
    transform: translate3d(22vw, 108vh, 0);
  }
  to {
    transform: translate3d(51vw, -112vh, 0);
  }
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(22vw, 108vh, 0);
  }
  to {
    transform: translate3d(51vw, -112vh, 0);
  }
}
.circle-container:nth-child(66) .circle {
  -webkit-animation-delay: 2047ms;
          animation-delay: 2047ms;
}
.circle-container:nth-child(67) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-67;
          animation-name: move-frames-67;
  -webkit-animation-duration: 30224ms;
          animation-duration: 30224ms;
  -webkit-animation-delay: 22191ms;
          animation-delay: 22191ms;
}
@-webkit-keyframes move-frames-67 {
  from {
    transform: translate3d(84vw, 101vh, 0);
  }
  to {
    transform: translate3d(97vw, -109vh, 0);
  }
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(84vw, 101vh, 0);
  }
  to {
    transform: translate3d(97vw, -109vh, 0);
  }
}
.circle-container:nth-child(67) .circle {
  -webkit-animation-delay: 3057ms;
          animation-delay: 3057ms;
}
.circle-container:nth-child(68) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-68;
          animation-name: move-frames-68;
  -webkit-animation-duration: 33792ms;
          animation-duration: 33792ms;
  -webkit-animation-delay: 35526ms;
          animation-delay: 35526ms;
}
@-webkit-keyframes move-frames-68 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(78vw, -128vh, 0);
  }
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(78vw, -128vh, 0);
  }
}
.circle-container:nth-child(68) .circle {
  -webkit-animation-delay: 2676ms;
          animation-delay: 2676ms;
}
.circle-container:nth-child(69) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-69;
          animation-name: move-frames-69;
  -webkit-animation-duration: 30898ms;
          animation-duration: 30898ms;
  -webkit-animation-delay: 24406ms;
          animation-delay: 24406ms;
}
@-webkit-keyframes move-frames-69 {
  from {
    transform: translate3d(66vw, 106vh, 0);
  }
  to {
    transform: translate3d(14vw, -123vh, 0);
  }
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(66vw, 106vh, 0);
  }
  to {
    transform: translate3d(14vw, -123vh, 0);
  }
}
.circle-container:nth-child(69) .circle {
  -webkit-animation-delay: 248ms;
          animation-delay: 248ms;
}
.circle-container:nth-child(70) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-70;
          animation-name: move-frames-70;
  -webkit-animation-duration: 30216ms;
          animation-duration: 30216ms;
  -webkit-animation-delay: 32650ms;
          animation-delay: 32650ms;
}
@-webkit-keyframes move-frames-70 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
.circle-container:nth-child(70) .circle {
  -webkit-animation-delay: 2935ms;
          animation-delay: 2935ms;
}
.circle-container:nth-child(71) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-71;
          animation-name: move-frames-71;
  -webkit-animation-duration: 32580ms;
          animation-duration: 32580ms;
  -webkit-animation-delay: 17279ms;
          animation-delay: 17279ms;
}
@-webkit-keyframes move-frames-71 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(71) .circle {
  -webkit-animation-delay: 3771ms;
          animation-delay: 3771ms;
}
.circle-container:nth-child(72) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-72;
          animation-name: move-frames-72;
  -webkit-animation-duration: 36575ms;
          animation-duration: 36575ms;
  -webkit-animation-delay: 13328ms;
          animation-delay: 13328ms;
}
@-webkit-keyframes move-frames-72 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -129vh, 0);
  }
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -129vh, 0);
  }
}
.circle-container:nth-child(72) .circle {
  -webkit-animation-delay: 776ms;
          animation-delay: 776ms;
}
.circle-container:nth-child(73) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-73;
          animation-name: move-frames-73;
  -webkit-animation-duration: 33906ms;
          animation-duration: 33906ms;
  -webkit-animation-delay: 265ms;
          animation-delay: 265ms;
}
@-webkit-keyframes move-frames-73 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(25vw, -119vh, 0);
  }
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(25vw, -119vh, 0);
  }
}
.circle-container:nth-child(73) .circle {
  -webkit-animation-delay: 2986ms;
          animation-delay: 2986ms;
}
.circle-container:nth-child(74) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-74;
          animation-name: move-frames-74;
  -webkit-animation-duration: 30787ms;
          animation-duration: 30787ms;
  -webkit-animation-delay: 23365ms;
          animation-delay: 23365ms;
}
@-webkit-keyframes move-frames-74 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -124vh, 0);
  }
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -124vh, 0);
  }
}
.circle-container:nth-child(74) .circle {
  -webkit-animation-delay: 3542ms;
          animation-delay: 3542ms;
}
.circle-container:nth-child(75) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-75;
          animation-name: move-frames-75;
  -webkit-animation-duration: 33173ms;
          animation-duration: 33173ms;
  -webkit-animation-delay: 32188ms;
          animation-delay: 32188ms;
}
@-webkit-keyframes move-frames-75 {
  from {
    transform: translate3d(65vw, 109vh, 0);
  }
  to {
    transform: translate3d(5vw, -117vh, 0);
  }
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(65vw, 109vh, 0);
  }
  to {
    transform: translate3d(5vw, -117vh, 0);
  }
}
.circle-container:nth-child(75) .circle {
  -webkit-animation-delay: 3578ms;
          animation-delay: 3578ms;
}
.circle-container:nth-child(76) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-76;
          animation-name: move-frames-76;
  -webkit-animation-duration: 31296ms;
          animation-duration: 31296ms;
  -webkit-animation-delay: 15074ms;
          animation-delay: 15074ms;
}
@-webkit-keyframes move-frames-76 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(84vw, -109vh, 0);
  }
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(84vw, -109vh, 0);
  }
}
.circle-container:nth-child(76) .circle {
  -webkit-animation-delay: 3626ms;
          animation-delay: 3626ms;
}
.circle-container:nth-child(77) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-77;
          animation-name: move-frames-77;
  -webkit-animation-duration: 35352ms;
          animation-duration: 35352ms;
  -webkit-animation-delay: 32643ms;
          animation-delay: 32643ms;
}
@-webkit-keyframes move-frames-77 {
  from {
    transform: translate3d(20vw, 101vh, 0);
  }
  to {
    transform: translate3d(2vw, -106vh, 0);
  }
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(20vw, 101vh, 0);
  }
  to {
    transform: translate3d(2vw, -106vh, 0);
  }
}
.circle-container:nth-child(77) .circle {
  -webkit-animation-delay: 3160ms;
          animation-delay: 3160ms;
}
.circle-container:nth-child(78) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-78;
          animation-name: move-frames-78;
  -webkit-animation-duration: 33470ms;
          animation-duration: 33470ms;
  -webkit-animation-delay: 5480ms;
          animation-delay: 5480ms;
}
@-webkit-keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -110vh, 0);
  }
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -110vh, 0);
  }
}
.circle-container:nth-child(78) .circle {
  -webkit-animation-delay: 460ms;
          animation-delay: 460ms;
}
.circle-container:nth-child(79) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-79;
          animation-name: move-frames-79;
  -webkit-animation-duration: 36776ms;
          animation-duration: 36776ms;
  -webkit-animation-delay: 33301ms;
          animation-delay: 33301ms;
}
@-webkit-keyframes move-frames-79 {
  from {
    transform: translate3d(25vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -112vh, 0);
  }
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(25vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -112vh, 0);
  }
}
.circle-container:nth-child(79) .circle {
  -webkit-animation-delay: 413ms;
          animation-delay: 413ms;
}
.circle-container:nth-child(80) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-80;
          animation-name: move-frames-80;
  -webkit-animation-duration: 33101ms;
          animation-duration: 33101ms;
  -webkit-animation-delay: 16510ms;
          animation-delay: 16510ms;
}
@-webkit-keyframes move-frames-80 {
  from {
    transform: translate3d(5vw, 109vh, 0);
  }
  to {
    transform: translate3d(14vw, -127vh, 0);
  }
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(5vw, 109vh, 0);
  }
  to {
    transform: translate3d(14vw, -127vh, 0);
  }
}
.circle-container:nth-child(80) .circle {
  -webkit-animation-delay: 793ms;
          animation-delay: 793ms;
}
.circle-container:nth-child(81) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-81;
          animation-name: move-frames-81;
  -webkit-animation-duration: 28644ms;
          animation-duration: 28644ms;
  -webkit-animation-delay: 8615ms;
          animation-delay: 8615ms;
}
@-webkit-keyframes move-frames-81 {
  from {
    transform: translate3d(43vw, 104vh, 0);
  }
  to {
    transform: translate3d(4vw, -115vh, 0);
  }
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(43vw, 104vh, 0);
  }
  to {
    transform: translate3d(4vw, -115vh, 0);
  }
}
.circle-container:nth-child(81) .circle {
  -webkit-animation-delay: 1533ms;
          animation-delay: 1533ms;
}
.circle-container:nth-child(82) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-82;
          animation-name: move-frames-82;
  -webkit-animation-duration: 29725ms;
          animation-duration: 29725ms;
  -webkit-animation-delay: 7835ms;
          animation-delay: 7835ms;
}
@-webkit-keyframes move-frames-82 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(68vw, -133vh, 0);
  }
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(68vw, -133vh, 0);
  }
}
.circle-container:nth-child(82) .circle {
  -webkit-animation-delay: 3452ms;
          animation-delay: 3452ms;
}
.circle-container:nth-child(83) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-83;
          animation-name: move-frames-83;
  -webkit-animation-duration: 36861ms;
          animation-duration: 36861ms;
  -webkit-animation-delay: 29593ms;
          animation-delay: 29593ms;
}
@-webkit-keyframes move-frames-83 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -112vh, 0);
  }
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -112vh, 0);
  }
}
.circle-container:nth-child(83) .circle {
  -webkit-animation-delay: 1445ms;
          animation-delay: 1445ms;
}
.circle-container:nth-child(84) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-84;
          animation-name: move-frames-84;
  -webkit-animation-duration: 36394ms;
          animation-duration: 36394ms;
  -webkit-animation-delay: 20654ms;
          animation-delay: 20654ms;
}
@-webkit-keyframes move-frames-84 {
  from {
    transform: translate3d(12vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(12vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
.circle-container:nth-child(84) .circle {
  -webkit-animation-delay: 1523ms;
          animation-delay: 1523ms;
}
.circle-container:nth-child(85) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-85;
          animation-name: move-frames-85;
  -webkit-animation-duration: 36739ms;
          animation-duration: 36739ms;
  -webkit-animation-delay: 7716ms;
          animation-delay: 7716ms;
}
@-webkit-keyframes move-frames-85 {
  from {
    transform: translate3d(23vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(23vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
.circle-container:nth-child(85) .circle {
  -webkit-animation-delay: 1038ms;
          animation-delay: 1038ms;
}
.circle-container:nth-child(86) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-86;
          animation-name: move-frames-86;
  -webkit-animation-duration: 30476ms;
          animation-duration: 30476ms;
  -webkit-animation-delay: 1242ms;
          animation-delay: 1242ms;
}
@-webkit-keyframes move-frames-86 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
.circle-container:nth-child(86) .circle {
  -webkit-animation-delay: 157ms;
          animation-delay: 157ms;
}
.circle-container:nth-child(87) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-87;
          animation-name: move-frames-87;
  -webkit-animation-duration: 29100ms;
          animation-duration: 29100ms;
  -webkit-animation-delay: 16859ms;
          animation-delay: 16859ms;
}
@-webkit-keyframes move-frames-87 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }
  to {
    transform: translate3d(43vw, -123vh, 0);
  }
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }
  to {
    transform: translate3d(43vw, -123vh, 0);
  }
}
.circle-container:nth-child(87) .circle {
  -webkit-animation-delay: 1676ms;
          animation-delay: 1676ms;
}
.circle-container:nth-child(88) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-88;
          animation-name: move-frames-88;
  -webkit-animation-duration: 30746ms;
          animation-duration: 30746ms;
  -webkit-animation-delay: 26439ms;
          animation-delay: 26439ms;
}
@-webkit-keyframes move-frames-88 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(81vw, -107vh, 0);
  }
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(81vw, -107vh, 0);
  }
}
.circle-container:nth-child(88) .circle {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.circle-container:nth-child(89) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-89;
          animation-name: move-frames-89;
  -webkit-animation-duration: 35918ms;
          animation-duration: 35918ms;
  -webkit-animation-delay: 35924ms;
          animation-delay: 35924ms;
}
@-webkit-keyframes move-frames-89 {
  from {
    transform: translate3d(13vw, 105vh, 0);
  }
  to {
    transform: translate3d(81vw, -125vh, 0);
  }
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(13vw, 105vh, 0);
  }
  to {
    transform: translate3d(81vw, -125vh, 0);
  }
}
.circle-container:nth-child(89) .circle {
  -webkit-animation-delay: 2579ms;
          animation-delay: 2579ms;
}
.circle-container:nth-child(90) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-90;
          animation-name: move-frames-90;
  -webkit-animation-duration: 34653ms;
          animation-duration: 34653ms;
  -webkit-animation-delay: 26813ms;
          animation-delay: 26813ms;
}
@-webkit-keyframes move-frames-90 {
  from {
    transform: translate3d(51vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -134vh, 0);
  }
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(51vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -134vh, 0);
  }
}
.circle-container:nth-child(90) .circle {
  -webkit-animation-delay: 3117ms;
          animation-delay: 3117ms;
}
.circle-container:nth-child(91) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-91;
          animation-name: move-frames-91;
  -webkit-animation-duration: 34629ms;
          animation-duration: 34629ms;
  -webkit-animation-delay: 29525ms;
          animation-delay: 29525ms;
}
@-webkit-keyframes move-frames-91 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -118vh, 0);
  }
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -118vh, 0);
  }
}
.circle-container:nth-child(91) .circle {
  -webkit-animation-delay: 2183ms;
          animation-delay: 2183ms;
}
.circle-container:nth-child(92) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-92;
          animation-name: move-frames-92;
  -webkit-animation-duration: 33327ms;
          animation-duration: 33327ms;
  -webkit-animation-delay: 1278ms;
          animation-delay: 1278ms;
}
@-webkit-keyframes move-frames-92 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(45vw, -110vh, 0);
  }
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(45vw, -110vh, 0);
  }
}
.circle-container:nth-child(92) .circle {
  -webkit-animation-delay: 3611ms;
          animation-delay: 3611ms;
}
.circle-container:nth-child(93) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-93;
          animation-name: move-frames-93;
  -webkit-animation-duration: 30830ms;
          animation-duration: 30830ms;
  -webkit-animation-delay: 18847ms;
          animation-delay: 18847ms;
}
@-webkit-keyframes move-frames-93 {
  from {
    transform: translate3d(62vw, 107vh, 0);
  }
  to {
    transform: translate3d(11vw, -132vh, 0);
  }
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(62vw, 107vh, 0);
  }
  to {
    transform: translate3d(11vw, -132vh, 0);
  }
}
.circle-container:nth-child(93) .circle {
  -webkit-animation-delay: 2319ms;
          animation-delay: 2319ms;
}
.circle-container:nth-child(94) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-94;
          animation-name: move-frames-94;
  -webkit-animation-duration: 30039ms;
          animation-duration: 30039ms;
  -webkit-animation-delay: 32456ms;
          animation-delay: 32456ms;
}
@-webkit-keyframes move-frames-94 {
  from {
    transform: translate3d(28vw, 104vh, 0);
  }
  to {
    transform: translate3d(71vw, -117vh, 0);
  }
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(28vw, 104vh, 0);
  }
  to {
    transform: translate3d(71vw, -117vh, 0);
  }
}
.circle-container:nth-child(94) .circle {
  -webkit-animation-delay: 2099ms;
          animation-delay: 2099ms;
}
.circle-container:nth-child(95) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-95;
          animation-name: move-frames-95;
  -webkit-animation-duration: 35042ms;
          animation-duration: 35042ms;
  -webkit-animation-delay: 14683ms;
          animation-delay: 14683ms;
}
@-webkit-keyframes move-frames-95 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }
  to {
    transform: translate3d(89vw, -119vh, 0);
  }
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }
  to {
    transform: translate3d(89vw, -119vh, 0);
  }
}
.circle-container:nth-child(95) .circle {
  -webkit-animation-delay: 3167ms;
          animation-delay: 3167ms;
}
.circle-container:nth-child(96) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-96;
          animation-name: move-frames-96;
  -webkit-animation-duration: 30596ms;
          animation-duration: 30596ms;
  -webkit-animation-delay: 25606ms;
          animation-delay: 25606ms;
}
@-webkit-keyframes move-frames-96 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
.circle-container:nth-child(96) .circle {
  -webkit-animation-delay: 3171ms;
          animation-delay: 3171ms;
}
.circle-container:nth-child(97) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-97;
          animation-name: move-frames-97;
  -webkit-animation-duration: 35889ms;
          animation-duration: 35889ms;
  -webkit-animation-delay: 9788ms;
          animation-delay: 9788ms;
}
@-webkit-keyframes move-frames-97 {
  from {
    transform: translate3d(56vw, 106vh, 0);
  }
  to {
    transform: translate3d(84vw, -126vh, 0);
  }
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(56vw, 106vh, 0);
  }
  to {
    transform: translate3d(84vw, -126vh, 0);
  }
}
.circle-container:nth-child(97) .circle {
  -webkit-animation-delay: 934ms;
          animation-delay: 934ms;
}
.circle-container:nth-child(98) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-98;
          animation-name: move-frames-98;
  -webkit-animation-duration: 35983ms;
          animation-duration: 35983ms;
  -webkit-animation-delay: 5664ms;
          animation-delay: 5664ms;
}
@-webkit-keyframes move-frames-98 {
  from {
    transform: translate3d(98vw, 105vh, 0);
  }
  to {
    transform: translate3d(64vw, -115vh, 0);
  }
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(98vw, 105vh, 0);
  }
  to {
    transform: translate3d(64vw, -115vh, 0);
  }
}
.circle-container:nth-child(98) .circle {
  -webkit-animation-delay: 3069ms;
          animation-delay: 3069ms;
}
.circle-container:nth-child(99) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-99;
          animation-name: move-frames-99;
  -webkit-animation-duration: 35627ms;
          animation-duration: 35627ms;
  -webkit-animation-delay: 19496ms;
          animation-delay: 19496ms;
}
@-webkit-keyframes move-frames-99 {
  from {
    transform: translate3d(96vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -113vh, 0);
  }
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(96vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -113vh, 0);
  }
}
.circle-container:nth-child(99) .circle {
  -webkit-animation-delay: 262ms;
          animation-delay: 262ms;
}
.circle-container:nth-child(100) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-100;
          animation-name: move-frames-100;
  -webkit-animation-duration: 31298ms;
          animation-duration: 31298ms;
  -webkit-animation-delay: 9191ms;
          animation-delay: 9191ms;
}
@-webkit-keyframes move-frames-100 {
  from {
    transform: translate3d(94vw, 109vh, 0);
  }
  to {
    transform: translate3d(32vw, -112vh, 0);
  }
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(94vw, 109vh, 0);
  }
  to {
    transform: translate3d(32vw, -112vh, 0);
  }
}
.circle-container:nth-child(100) .circle {
  -webkit-animation-delay: 457ms;
          animation-delay: 457ms;
}
.circle-container:nth-child(101) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-101;
          animation-name: move-frames-101;
  -webkit-animation-duration: 32806ms;
          animation-duration: 32806ms;
  -webkit-animation-delay: 36642ms;
          animation-delay: 36642ms;
}
@-webkit-keyframes move-frames-101 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -109vh, 0);
  }
}
@keyframes move-frames-101 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -109vh, 0);
  }
}
.circle-container:nth-child(101) .circle {
  -webkit-animation-delay: 3620ms;
          animation-delay: 3620ms;
}
.circle-container:nth-child(102) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-102;
          animation-name: move-frames-102;
  -webkit-animation-duration: 34794ms;
          animation-duration: 34794ms;
  -webkit-animation-delay: 4790ms;
          animation-delay: 4790ms;
}
@-webkit-keyframes move-frames-102 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}
@keyframes move-frames-102 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}
.circle-container:nth-child(102) .circle {
  -webkit-animation-delay: 3558ms;
          animation-delay: 3558ms;
}
.circle-container:nth-child(103) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-103;
          animation-name: move-frames-103;
  -webkit-animation-duration: 32273ms;
          animation-duration: 32273ms;
  -webkit-animation-delay: 36344ms;
          animation-delay: 36344ms;
}
@-webkit-keyframes move-frames-103 {
  from {
    transform: translate3d(49vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -110vh, 0);
  }
}
@keyframes move-frames-103 {
  from {
    transform: translate3d(49vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -110vh, 0);
  }
}
.circle-container:nth-child(103) .circle {
  -webkit-animation-delay: 78ms;
          animation-delay: 78ms;
}
.circle-container:nth-child(104) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-104;
          animation-name: move-frames-104;
  -webkit-animation-duration: 36727ms;
          animation-duration: 36727ms;
  -webkit-animation-delay: 32419ms;
          animation-delay: 32419ms;
}
@-webkit-keyframes move-frames-104 {
  from {
    transform: translate3d(21vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -120vh, 0);
  }
}
@keyframes move-frames-104 {
  from {
    transform: translate3d(21vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -120vh, 0);
  }
}
.circle-container:nth-child(104) .circle {
  -webkit-animation-delay: 191ms;
          animation-delay: 191ms;
}
.circle-container:nth-child(105) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-105;
          animation-name: move-frames-105;
  -webkit-animation-duration: 35021ms;
          animation-duration: 35021ms;
  -webkit-animation-delay: 22566ms;
          animation-delay: 22566ms;
}
@-webkit-keyframes move-frames-105 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(48vw, -109vh, 0);
  }
}
@keyframes move-frames-105 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(48vw, -109vh, 0);
  }
}
.circle-container:nth-child(105) .circle {
  -webkit-animation-delay: 3044ms;
          animation-delay: 3044ms;
}
.circle-container:nth-child(106) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-106;
          animation-name: move-frames-106;
  -webkit-animation-duration: 34807ms;
          animation-duration: 34807ms;
  -webkit-animation-delay: 24797ms;
          animation-delay: 24797ms;
}
@-webkit-keyframes move-frames-106 {
  from {
    transform: translate3d(59vw, 108vh, 0);
  }
  to {
    transform: translate3d(79vw, -124vh, 0);
  }
}
@keyframes move-frames-106 {
  from {
    transform: translate3d(59vw, 108vh, 0);
  }
  to {
    transform: translate3d(79vw, -124vh, 0);
  }
}
.circle-container:nth-child(106) .circle {
  -webkit-animation-delay: 226ms;
          animation-delay: 226ms;
}
.circle-container:nth-child(107) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-107;
          animation-name: move-frames-107;
  -webkit-animation-duration: 32052ms;
          animation-duration: 32052ms;
  -webkit-animation-delay: 7227ms;
          animation-delay: 7227ms;
}
@-webkit-keyframes move-frames-107 {
  from {
    transform: translate3d(56vw, 102vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
@keyframes move-frames-107 {
  from {
    transform: translate3d(56vw, 102vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
.circle-container:nth-child(107) .circle {
  -webkit-animation-delay: 3768ms;
          animation-delay: 3768ms;
}
.circle-container:nth-child(108) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-108;
          animation-name: move-frames-108;
  -webkit-animation-duration: 32403ms;
          animation-duration: 32403ms;
  -webkit-animation-delay: 36237ms;
          animation-delay: 36237ms;
}
@-webkit-keyframes move-frames-108 {
  from {
    transform: translate3d(21vw, 103vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
@keyframes move-frames-108 {
  from {
    transform: translate3d(21vw, 103vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
.circle-container:nth-child(108) .circle {
  -webkit-animation-delay: 673ms;
          animation-delay: 673ms;
}
.circle-container:nth-child(109) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-109;
          animation-name: move-frames-109;
  -webkit-animation-duration: 31764ms;
          animation-duration: 31764ms;
  -webkit-animation-delay: 27936ms;
          animation-delay: 27936ms;
}
@-webkit-keyframes move-frames-109 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(19vw, -135vh, 0);
  }
}
@keyframes move-frames-109 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(19vw, -135vh, 0);
  }
}
.circle-container:nth-child(109) .circle {
  -webkit-animation-delay: 3819ms;
          animation-delay: 3819ms;
}
.circle-container:nth-child(110) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-110;
          animation-name: move-frames-110;
  -webkit-animation-duration: 33961ms;
          animation-duration: 33961ms;
  -webkit-animation-delay: 30019ms;
          animation-delay: 30019ms;
}
@-webkit-keyframes move-frames-110 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(2vw, -117vh, 0);
  }
}
@keyframes move-frames-110 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(2vw, -117vh, 0);
  }
}
.circle-container:nth-child(110) .circle {
  -webkit-animation-delay: 3572ms;
          animation-delay: 3572ms;
}
.circle-container:nth-child(111) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-111;
          animation-name: move-frames-111;
  -webkit-animation-duration: 35304ms;
          animation-duration: 35304ms;
  -webkit-animation-delay: 24053ms;
          animation-delay: 24053ms;
}
@-webkit-keyframes move-frames-111 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(40vw, -118vh, 0);
  }
}
@keyframes move-frames-111 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(40vw, -118vh, 0);
  }
}
.circle-container:nth-child(111) .circle {
  -webkit-animation-delay: 2914ms;
          animation-delay: 2914ms;
}
.circle-container:nth-child(112) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-112;
          animation-name: move-frames-112;
  -webkit-animation-duration: 33778ms;
          animation-duration: 33778ms;
  -webkit-animation-delay: 35021ms;
          animation-delay: 35021ms;
}
@-webkit-keyframes move-frames-112 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -112vh, 0);
  }
}
@keyframes move-frames-112 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -112vh, 0);
  }
}
.circle-container:nth-child(112) .circle {
  -webkit-animation-delay: 770ms;
          animation-delay: 770ms;
}
.circle-container:nth-child(113) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-113;
          animation-name: move-frames-113;
  -webkit-animation-duration: 33297ms;
          animation-duration: 33297ms;
  -webkit-animation-delay: 7019ms;
          animation-delay: 7019ms;
}
@-webkit-keyframes move-frames-113 {
  from {
    transform: translate3d(15vw, 102vh, 0);
  }
  to {
    transform: translate3d(92vw, -107vh, 0);
  }
}
@keyframes move-frames-113 {
  from {
    transform: translate3d(15vw, 102vh, 0);
  }
  to {
    transform: translate3d(92vw, -107vh, 0);
  }
}
.circle-container:nth-child(113) .circle {
  -webkit-animation-delay: 1945ms;
          animation-delay: 1945ms;
}
.circle-container:nth-child(114) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-114;
          animation-name: move-frames-114;
  -webkit-animation-duration: 30134ms;
          animation-duration: 30134ms;
  -webkit-animation-delay: 19510ms;
          animation-delay: 19510ms;
}
@-webkit-keyframes move-frames-114 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-114 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(114) .circle {
  -webkit-animation-delay: 2739ms;
          animation-delay: 2739ms;
}
.circle-container:nth-child(115) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-115;
          animation-name: move-frames-115;
  -webkit-animation-duration: 34127ms;
          animation-duration: 34127ms;
  -webkit-animation-delay: 31543ms;
          animation-delay: 31543ms;
}
@-webkit-keyframes move-frames-115 {
  from {
    transform: translate3d(39vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -130vh, 0);
  }
}
@keyframes move-frames-115 {
  from {
    transform: translate3d(39vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -130vh, 0);
  }
}
.circle-container:nth-child(115) .circle {
  -webkit-animation-delay: 2113ms;
          animation-delay: 2113ms;
}
.circle-container:nth-child(116) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-116;
          animation-name: move-frames-116;
  -webkit-animation-duration: 32705ms;
          animation-duration: 32705ms;
  -webkit-animation-delay: 9971ms;
          animation-delay: 9971ms;
}
@-webkit-keyframes move-frames-116 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -110vh, 0);
  }
}
@keyframes move-frames-116 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -110vh, 0);
  }
}
.circle-container:nth-child(116) .circle {
  -webkit-animation-delay: 737ms;
          animation-delay: 737ms;
}
.circle-container:nth-child(117) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-117;
          animation-name: move-frames-117;
  -webkit-animation-duration: 30581ms;
          animation-duration: 30581ms;
  -webkit-animation-delay: 5663ms;
          animation-delay: 5663ms;
}
@-webkit-keyframes move-frames-117 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -120vh, 0);
  }
}
@keyframes move-frames-117 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -120vh, 0);
  }
}
.circle-container:nth-child(117) .circle {
  -webkit-animation-delay: 2971ms;
          animation-delay: 2971ms;
}
.circle-container:nth-child(118) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-118;
          animation-name: move-frames-118;
  -webkit-animation-duration: 31572ms;
          animation-duration: 31572ms;
  -webkit-animation-delay: 7668ms;
          animation-delay: 7668ms;
}
@-webkit-keyframes move-frames-118 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -133vh, 0);
  }
}
@keyframes move-frames-118 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -133vh, 0);
  }
}
.circle-container:nth-child(118) .circle {
  -webkit-animation-delay: 1474ms;
          animation-delay: 1474ms;
}
.circle-container:nth-child(119) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-119;
          animation-name: move-frames-119;
  -webkit-animation-duration: 28231ms;
          animation-duration: 28231ms;
  -webkit-animation-delay: 10078ms;
          animation-delay: 10078ms;
}
@-webkit-keyframes move-frames-119 {
  from {
    transform: translate3d(13vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -132vh, 0);
  }
}
@keyframes move-frames-119 {
  from {
    transform: translate3d(13vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -132vh, 0);
  }
}
.circle-container:nth-child(119) .circle {
  -webkit-animation-delay: 1246ms;
          animation-delay: 1246ms;
}
.circle-container:nth-child(120) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-120;
          animation-name: move-frames-120;
  -webkit-animation-duration: 32014ms;
          animation-duration: 32014ms;
  -webkit-animation-delay: 31660ms;
          animation-delay: 31660ms;
}
@-webkit-keyframes move-frames-120 {
  from {
    transform: translate3d(54vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
@keyframes move-frames-120 {
  from {
    transform: translate3d(54vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
.circle-container:nth-child(120) .circle {
  -webkit-animation-delay: 2513ms;
          animation-delay: 2513ms;
}
.circle-container:nth-child(121) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-121;
          animation-name: move-frames-121;
  -webkit-animation-duration: 33562ms;
          animation-duration: 33562ms;
  -webkit-animation-delay: 3707ms;
          animation-delay: 3707ms;
}
@-webkit-keyframes move-frames-121 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -127vh, 0);
  }
}
@keyframes move-frames-121 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -127vh, 0);
  }
}
.circle-container:nth-child(121) .circle {
  -webkit-animation-delay: 2991ms;
          animation-delay: 2991ms;
}
.circle-container:nth-child(122) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-122;
          animation-name: move-frames-122;
  -webkit-animation-duration: 35907ms;
          animation-duration: 35907ms;
  -webkit-animation-delay: 14480ms;
          animation-delay: 14480ms;
}
@-webkit-keyframes move-frames-122 {
  from {
    transform: translate3d(21vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -136vh, 0);
  }
}
@keyframes move-frames-122 {
  from {
    transform: translate3d(21vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -136vh, 0);
  }
}
.circle-container:nth-child(122) .circle {
  -webkit-animation-delay: 103ms;
          animation-delay: 103ms;
}
.circle-container:nth-child(123) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-123;
          animation-name: move-frames-123;
  -webkit-animation-duration: 32346ms;
          animation-duration: 32346ms;
  -webkit-animation-delay: 4030ms;
          animation-delay: 4030ms;
}
@-webkit-keyframes move-frames-123 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(42vw, -110vh, 0);
  }
}
@keyframes move-frames-123 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(42vw, -110vh, 0);
  }
}
.circle-container:nth-child(123) .circle {
  -webkit-animation-delay: 2421ms;
          animation-delay: 2421ms;
}
.circle-container:nth-child(124) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-124;
          animation-name: move-frames-124;
  -webkit-animation-duration: 36605ms;
          animation-duration: 36605ms;
  -webkit-animation-delay: 34089ms;
          animation-delay: 34089ms;
}
@-webkit-keyframes move-frames-124 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -128vh, 0);
  }
}
@keyframes move-frames-124 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -128vh, 0);
  }
}
.circle-container:nth-child(124) .circle {
  -webkit-animation-delay: 2359ms;
          animation-delay: 2359ms;
}
.circle-container:nth-child(125) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-125;
          animation-name: move-frames-125;
  -webkit-animation-duration: 33487ms;
          animation-duration: 33487ms;
  -webkit-animation-delay: 29870ms;
          animation-delay: 29870ms;
}
@-webkit-keyframes move-frames-125 {
  from {
    transform: translate3d(23vw, 109vh, 0);
  }
  to {
    transform: translate3d(46vw, -137vh, 0);
  }
}
@keyframes move-frames-125 {
  from {
    transform: translate3d(23vw, 109vh, 0);
  }
  to {
    transform: translate3d(46vw, -137vh, 0);
  }
}
.circle-container:nth-child(125) .circle {
  -webkit-animation-delay: 3027ms;
          animation-delay: 3027ms;
}
.circle-container:nth-child(126) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-126;
          animation-name: move-frames-126;
  -webkit-animation-duration: 33514ms;
          animation-duration: 33514ms;
  -webkit-animation-delay: 12126ms;
          animation-delay: 12126ms;
}
@-webkit-keyframes move-frames-126 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(89vw, -114vh, 0);
  }
}
@keyframes move-frames-126 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(89vw, -114vh, 0);
  }
}
.circle-container:nth-child(126) .circle {
  -webkit-animation-delay: 1164ms;
          animation-delay: 1164ms;
}
.circle-container:nth-child(127) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-127;
          animation-name: move-frames-127;
  -webkit-animation-duration: 33434ms;
          animation-duration: 33434ms;
  -webkit-animation-delay: 11204ms;
          animation-delay: 11204ms;
}
@-webkit-keyframes move-frames-127 {
  from {
    transform: translate3d(97vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -116vh, 0);
  }
}
@keyframes move-frames-127 {
  from {
    transform: translate3d(97vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -116vh, 0);
  }
}
.circle-container:nth-child(127) .circle {
  -webkit-animation-delay: 3036ms;
          animation-delay: 3036ms;
}
.circle-container:nth-child(128) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-128;
          animation-name: move-frames-128;
  -webkit-animation-duration: 31121ms;
          animation-duration: 31121ms;
  -webkit-animation-delay: 9932ms;
          animation-delay: 9932ms;
}
@-webkit-keyframes move-frames-128 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(99vw, -111vh, 0);
  }
}
@keyframes move-frames-128 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(99vw, -111vh, 0);
  }
}
.circle-container:nth-child(128) .circle {
  -webkit-animation-delay: 3065ms;
          animation-delay: 3065ms;
}
.circle-container:nth-child(129) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-129;
          animation-name: move-frames-129;
  -webkit-animation-duration: 29360ms;
          animation-duration: 29360ms;
  -webkit-animation-delay: 4553ms;
          animation-delay: 4553ms;
}
@-webkit-keyframes move-frames-129 {
  from {
    transform: translate3d(28vw, 110vh, 0);
  }
  to {
    transform: translate3d(15vw, -137vh, 0);
  }
}
@keyframes move-frames-129 {
  from {
    transform: translate3d(28vw, 110vh, 0);
  }
  to {
    transform: translate3d(15vw, -137vh, 0);
  }
}
.circle-container:nth-child(129) .circle {
  -webkit-animation-delay: 1022ms;
          animation-delay: 1022ms;
}
.circle-container:nth-child(130) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-130;
          animation-name: move-frames-130;
  -webkit-animation-duration: 36820ms;
          animation-duration: 36820ms;
  -webkit-animation-delay: 20024ms;
          animation-delay: 20024ms;
}
@-webkit-keyframes move-frames-130 {
  from {
    transform: translate3d(3vw, 108vh, 0);
  }
  to {
    transform: translate3d(84vw, -121vh, 0);
  }
}
@keyframes move-frames-130 {
  from {
    transform: translate3d(3vw, 108vh, 0);
  }
  to {
    transform: translate3d(84vw, -121vh, 0);
  }
}
.circle-container:nth-child(130) .circle {
  -webkit-animation-delay: 1349ms;
          animation-delay: 1349ms;
}
.circle-container:nth-child(131) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-131;
          animation-name: move-frames-131;
  -webkit-animation-duration: 36263ms;
          animation-duration: 36263ms;
  -webkit-animation-delay: 7730ms;
          animation-delay: 7730ms;
}
@-webkit-keyframes move-frames-131 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(19vw, -103vh, 0);
  }
}
@keyframes move-frames-131 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(19vw, -103vh, 0);
  }
}
.circle-container:nth-child(131) .circle {
  -webkit-animation-delay: 1684ms;
          animation-delay: 1684ms;
}
.circle-container:nth-child(132) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-132;
          animation-name: move-frames-132;
  -webkit-animation-duration: 35702ms;
          animation-duration: 35702ms;
  -webkit-animation-delay: 29446ms;
          animation-delay: 29446ms;
}
@-webkit-keyframes move-frames-132 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
@keyframes move-frames-132 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
.circle-container:nth-child(132) .circle {
  -webkit-animation-delay: 1162ms;
          animation-delay: 1162ms;
}
.circle-container:nth-child(133) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-133;
          animation-name: move-frames-133;
  -webkit-animation-duration: 36727ms;
          animation-duration: 36727ms;
  -webkit-animation-delay: 21261ms;
          animation-delay: 21261ms;
}
@-webkit-keyframes move-frames-133 {
  from {
    transform: translate3d(40vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -121vh, 0);
  }
}
@keyframes move-frames-133 {
  from {
    transform: translate3d(40vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -121vh, 0);
  }
}
.circle-container:nth-child(133) .circle {
  -webkit-animation-delay: 2802ms;
          animation-delay: 2802ms;
}
.circle-container:nth-child(134) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-134;
          animation-name: move-frames-134;
  -webkit-animation-duration: 30171ms;
          animation-duration: 30171ms;
  -webkit-animation-delay: 1903ms;
          animation-delay: 1903ms;
}
@-webkit-keyframes move-frames-134 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(91vw, -119vh, 0);
  }
}
@keyframes move-frames-134 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(91vw, -119vh, 0);
  }
}
.circle-container:nth-child(134) .circle {
  -webkit-animation-delay: 934ms;
          animation-delay: 934ms;
}
.circle-container:nth-child(135) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-135;
          animation-name: move-frames-135;
  -webkit-animation-duration: 30752ms;
          animation-duration: 30752ms;
  -webkit-animation-delay: 26203ms;
          animation-delay: 26203ms;
}
@-webkit-keyframes move-frames-135 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(7vw, -135vh, 0);
  }
}
@keyframes move-frames-135 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(7vw, -135vh, 0);
  }
}
.circle-container:nth-child(135) .circle {
  -webkit-animation-delay: 2757ms;
          animation-delay: 2757ms;
}
.circle-container:nth-child(136) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-136;
          animation-name: move-frames-136;
  -webkit-animation-duration: 29124ms;
          animation-duration: 29124ms;
  -webkit-animation-delay: 34221ms;
          animation-delay: 34221ms;
}
@-webkit-keyframes move-frames-136 {
  from {
    transform: translate3d(69vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}
@keyframes move-frames-136 {
  from {
    transform: translate3d(69vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}
.circle-container:nth-child(136) .circle {
  -webkit-animation-delay: 1617ms;
          animation-delay: 1617ms;
}
.circle-container:nth-child(137) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-137;
          animation-name: move-frames-137;
  -webkit-animation-duration: 28475ms;
          animation-duration: 28475ms;
  -webkit-animation-delay: 17746ms;
          animation-delay: 17746ms;
}
@-webkit-keyframes move-frames-137 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }
  to {
    transform: translate3d(35vw, -104vh, 0);
  }
}
@keyframes move-frames-137 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }
  to {
    transform: translate3d(35vw, -104vh, 0);
  }
}
.circle-container:nth-child(137) .circle {
  -webkit-animation-delay: 2070ms;
          animation-delay: 2070ms;
}
.circle-container:nth-child(138) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-138;
          animation-name: move-frames-138;
  -webkit-animation-duration: 29509ms;
          animation-duration: 29509ms;
  -webkit-animation-delay: 30357ms;
          animation-delay: 30357ms;
}
@-webkit-keyframes move-frames-138 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(66vw, -128vh, 0);
  }
}
@keyframes move-frames-138 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(66vw, -128vh, 0);
  }
}
.circle-container:nth-child(138) .circle {
  -webkit-animation-delay: 1235ms;
          animation-delay: 1235ms;
}
.circle-container:nth-child(139) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-139;
          animation-name: move-frames-139;
  -webkit-animation-duration: 28986ms;
          animation-duration: 28986ms;
  -webkit-animation-delay: 36174ms;
          animation-delay: 36174ms;
}
@-webkit-keyframes move-frames-139 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -132vh, 0);
  }
}
@keyframes move-frames-139 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -132vh, 0);
  }
}
.circle-container:nth-child(139) .circle {
  -webkit-animation-delay: 1540ms;
          animation-delay: 1540ms;
}
.circle-container:nth-child(140) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-140;
          animation-name: move-frames-140;
  -webkit-animation-duration: 36764ms;
          animation-duration: 36764ms;
  -webkit-animation-delay: 27362ms;
          animation-delay: 27362ms;
}
@-webkit-keyframes move-frames-140 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
@keyframes move-frames-140 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
.circle-container:nth-child(140) .circle {
  -webkit-animation-delay: 3563ms;
          animation-delay: 3563ms;
}
.circle-container:nth-child(141) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-141;
          animation-name: move-frames-141;
  -webkit-animation-duration: 35252ms;
          animation-duration: 35252ms;
  -webkit-animation-delay: 30694ms;
          animation-delay: 30694ms;
}
@-webkit-keyframes move-frames-141 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(68vw, -137vh, 0);
  }
}
@keyframes move-frames-141 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(68vw, -137vh, 0);
  }
}
.circle-container:nth-child(141) .circle {
  -webkit-animation-delay: 438ms;
          animation-delay: 438ms;
}
.circle-container:nth-child(142) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-142;
          animation-name: move-frames-142;
  -webkit-animation-duration: 31065ms;
          animation-duration: 31065ms;
  -webkit-animation-delay: 25027ms;
          animation-delay: 25027ms;
}
@-webkit-keyframes move-frames-142 {
  from {
    transform: translate3d(16vw, 105vh, 0);
  }
  to {
    transform: translate3d(71vw, -125vh, 0);
  }
}
@keyframes move-frames-142 {
  from {
    transform: translate3d(16vw, 105vh, 0);
  }
  to {
    transform: translate3d(71vw, -125vh, 0);
  }
}
.circle-container:nth-child(142) .circle {
  -webkit-animation-delay: 2784ms;
          animation-delay: 2784ms;
}
.circle-container:nth-child(143) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-143;
          animation-name: move-frames-143;
  -webkit-animation-duration: 29850ms;
          animation-duration: 29850ms;
  -webkit-animation-delay: 3647ms;
          animation-delay: 3647ms;
}
@-webkit-keyframes move-frames-143 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(93vw, -108vh, 0);
  }
}
@keyframes move-frames-143 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(93vw, -108vh, 0);
  }
}
.circle-container:nth-child(143) .circle {
  -webkit-animation-delay: 1819ms;
          animation-delay: 1819ms;
}
.circle-container:nth-child(144) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-144;
          animation-name: move-frames-144;
  -webkit-animation-duration: 34511ms;
          animation-duration: 34511ms;
  -webkit-animation-delay: 16341ms;
          animation-delay: 16341ms;
}
@-webkit-keyframes move-frames-144 {
  from {
    transform: translate3d(90vw, 104vh, 0);
  }
  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}
@keyframes move-frames-144 {
  from {
    transform: translate3d(90vw, 104vh, 0);
  }
  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}
.circle-container:nth-child(144) .circle {
  -webkit-animation-delay: 882ms;
          animation-delay: 882ms;
}
.circle-container:nth-child(145) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-145;
          animation-name: move-frames-145;
  -webkit-animation-duration: 35053ms;
          animation-duration: 35053ms;
  -webkit-animation-delay: 23288ms;
          animation-delay: 23288ms;
}
@-webkit-keyframes move-frames-145 {
  from {
    transform: translate3d(57vw, 108vh, 0);
  }
  to {
    transform: translate3d(32vw, -119vh, 0);
  }
}
@keyframes move-frames-145 {
  from {
    transform: translate3d(57vw, 108vh, 0);
  }
  to {
    transform: translate3d(32vw, -119vh, 0);
  }
}
.circle-container:nth-child(145) .circle {
  -webkit-animation-delay: 890ms;
          animation-delay: 890ms;
}
.circle-container:nth-child(146) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-146;
          animation-name: move-frames-146;
  -webkit-animation-duration: 35799ms;
          animation-duration: 35799ms;
  -webkit-animation-delay: 28218ms;
          animation-delay: 28218ms;
}
@-webkit-keyframes move-frames-146 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(76vw, -123vh, 0);
  }
}
@keyframes move-frames-146 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(76vw, -123vh, 0);
  }
}
.circle-container:nth-child(146) .circle {
  -webkit-animation-delay: 1696ms;
          animation-delay: 1696ms;
}
.circle-container:nth-child(147) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-147;
          animation-name: move-frames-147;
  -webkit-animation-duration: 31584ms;
          animation-duration: 31584ms;
  -webkit-animation-delay: 16302ms;
          animation-delay: 16302ms;
}
@-webkit-keyframes move-frames-147 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(65vw, -137vh, 0);
  }
}
@keyframes move-frames-147 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(65vw, -137vh, 0);
  }
}
.circle-container:nth-child(147) .circle {
  -webkit-animation-delay: 3229ms;
          animation-delay: 3229ms;
}
.circle-container:nth-child(148) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-148;
          animation-name: move-frames-148;
  -webkit-animation-duration: 28830ms;
          animation-duration: 28830ms;
  -webkit-animation-delay: 424ms;
          animation-delay: 424ms;
}
@-webkit-keyframes move-frames-148 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -135vh, 0);
  }
}
@keyframes move-frames-148 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -135vh, 0);
  }
}
.circle-container:nth-child(148) .circle {
  -webkit-animation-delay: 2408ms;
          animation-delay: 2408ms;
}
.circle-container:nth-child(149) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-149;
          animation-name: move-frames-149;
  -webkit-animation-duration: 34207ms;
          animation-duration: 34207ms;
  -webkit-animation-delay: 22155ms;
          animation-delay: 22155ms;
}
@-webkit-keyframes move-frames-149 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -126vh, 0);
  }
}
@keyframes move-frames-149 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -126vh, 0);
  }
}
.circle-container:nth-child(149) .circle {
  -webkit-animation-delay: 1050ms;
          animation-delay: 1050ms;
}
.circle-container:nth-child(150) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-150;
          animation-name: move-frames-150;
  -webkit-animation-duration: 31412ms;
          animation-duration: 31412ms;
  -webkit-animation-delay: 31637ms;
          animation-delay: 31637ms;
}
@-webkit-keyframes move-frames-150 {
  from {
    transform: translate3d(63vw, 102vh, 0);
  }
  to {
    transform: translate3d(46vw, -116vh, 0);
  }
}
@keyframes move-frames-150 {
  from {
    transform: translate3d(63vw, 102vh, 0);
  }
  to {
    transform: translate3d(46vw, -116vh, 0);
  }
}
.circle-container:nth-child(150) .circle {
  -webkit-animation-delay: 1878ms;
          animation-delay: 1878ms;
}
.circle-container:nth-child(151) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-151;
          animation-name: move-frames-151;
  -webkit-animation-duration: 35477ms;
          animation-duration: 35477ms;
  -webkit-animation-delay: 327ms;
          animation-delay: 327ms;
}
@-webkit-keyframes move-frames-151 {
  from {
    transform: translate3d(79vw, 105vh, 0);
  }
  to {
    transform: translate3d(67vw, -108vh, 0);
  }
}
@keyframes move-frames-151 {
  from {
    transform: translate3d(79vw, 105vh, 0);
  }
  to {
    transform: translate3d(67vw, -108vh, 0);
  }
}
.circle-container:nth-child(151) .circle {
  -webkit-animation-delay: 3444ms;
          animation-delay: 3444ms;
}
.circle-container:nth-child(152) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-152;
          animation-name: move-frames-152;
  -webkit-animation-duration: 32085ms;
          animation-duration: 32085ms;
  -webkit-animation-delay: 10440ms;
          animation-delay: 10440ms;
}
@-webkit-keyframes move-frames-152 {
  from {
    transform: translate3d(82vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -134vh, 0);
  }
}
@keyframes move-frames-152 {
  from {
    transform: translate3d(82vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -134vh, 0);
  }
}
.circle-container:nth-child(152) .circle {
  -webkit-animation-delay: 503ms;
          animation-delay: 503ms;
}
.circle-container:nth-child(153) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-153;
          animation-name: move-frames-153;
  -webkit-animation-duration: 33251ms;
          animation-duration: 33251ms;
  -webkit-animation-delay: 5213ms;
          animation-delay: 5213ms;
}
@-webkit-keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(33vw, -113vh, 0);
  }
}
@keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(33vw, -113vh, 0);
  }
}
.circle-container:nth-child(153) .circle {
  -webkit-animation-delay: 1589ms;
          animation-delay: 1589ms;
}
.circle-container:nth-child(154) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-154;
          animation-name: move-frames-154;
  -webkit-animation-duration: 31428ms;
          animation-duration: 31428ms;
  -webkit-animation-delay: 33933ms;
          animation-delay: 33933ms;
}
@-webkit-keyframes move-frames-154 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
@keyframes move-frames-154 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
.circle-container:nth-child(154) .circle {
  -webkit-animation-delay: 1790ms;
          animation-delay: 1790ms;
}
.circle-container:nth-child(155) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-155;
          animation-name: move-frames-155;
  -webkit-animation-duration: 35956ms;
          animation-duration: 35956ms;
  -webkit-animation-delay: 11137ms;
          animation-delay: 11137ms;
}
@-webkit-keyframes move-frames-155 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
@keyframes move-frames-155 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
.circle-container:nth-child(155) .circle {
  -webkit-animation-delay: 1061ms;
          animation-delay: 1061ms;
}
.circle-container:nth-child(156) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-156;
          animation-name: move-frames-156;
  -webkit-animation-duration: 32867ms;
          animation-duration: 32867ms;
  -webkit-animation-delay: 18692ms;
          animation-delay: 18692ms;
}
@-webkit-keyframes move-frames-156 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }
  to {
    transform: translate3d(58vw, -119vh, 0);
  }
}
@keyframes move-frames-156 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }
  to {
    transform: translate3d(58vw, -119vh, 0);
  }
}
.circle-container:nth-child(156) .circle {
  -webkit-animation-delay: 2984ms;
          animation-delay: 2984ms;
}
.circle-container:nth-child(157) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-157;
          animation-name: move-frames-157;
  -webkit-animation-duration: 33034ms;
          animation-duration: 33034ms;
  -webkit-animation-delay: 1339ms;
          animation-delay: 1339ms;
}
@-webkit-keyframes move-frames-157 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
@keyframes move-frames-157 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
.circle-container:nth-child(157) .circle {
  -webkit-animation-delay: 1971ms;
          animation-delay: 1971ms;
}
.circle-container:nth-child(158) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-158;
          animation-name: move-frames-158;
  -webkit-animation-duration: 33395ms;
          animation-duration: 33395ms;
  -webkit-animation-delay: 19506ms;
          animation-delay: 19506ms;
}
@-webkit-keyframes move-frames-158 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(33vw, -112vh, 0);
  }
}
@keyframes move-frames-158 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(33vw, -112vh, 0);
  }
}
.circle-container:nth-child(158) .circle {
  -webkit-animation-delay: 219ms;
          animation-delay: 219ms;
}
.circle-container:nth-child(159) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-159;
          animation-name: move-frames-159;
  -webkit-animation-duration: 30989ms;
          animation-duration: 30989ms;
  -webkit-animation-delay: 346ms;
          animation-delay: 346ms;
}
@-webkit-keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }
  to {
    transform: translate3d(88vw, -129vh, 0);
  }
}
@keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }
  to {
    transform: translate3d(88vw, -129vh, 0);
  }
}
.circle-container:nth-child(159) .circle {
  -webkit-animation-delay: 466ms;
          animation-delay: 466ms;
}
.circle-container:nth-child(160) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-160;
          animation-name: move-frames-160;
  -webkit-animation-duration: 32514ms;
          animation-duration: 32514ms;
  -webkit-animation-delay: 12899ms;
          animation-delay: 12899ms;
}
@-webkit-keyframes move-frames-160 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(36vw, -137vh, 0);
  }
}
@keyframes move-frames-160 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(36vw, -137vh, 0);
  }
}
.circle-container:nth-child(160) .circle {
  -webkit-animation-delay: 2684ms;
          animation-delay: 2684ms;
}
.circle-container:nth-child(161) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-161;
          animation-name: move-frames-161;
  -webkit-animation-duration: 28681ms;
          animation-duration: 28681ms;
  -webkit-animation-delay: 9536ms;
          animation-delay: 9536ms;
}
@-webkit-keyframes move-frames-161 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(32vw, -121vh, 0);
  }
}
@keyframes move-frames-161 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(32vw, -121vh, 0);
  }
}
.circle-container:nth-child(161) .circle {
  -webkit-animation-delay: 2044ms;
          animation-delay: 2044ms;
}
.circle-container:nth-child(162) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-162;
          animation-name: move-frames-162;
  -webkit-animation-duration: 30731ms;
          animation-duration: 30731ms;
  -webkit-animation-delay: 10531ms;
          animation-delay: 10531ms;
}
@-webkit-keyframes move-frames-162 {
  from {
    transform: translate3d(84vw, 104vh, 0);
  }
  to {
    transform: translate3d(63vw, -113vh, 0);
  }
}
@keyframes move-frames-162 {
  from {
    transform: translate3d(84vw, 104vh, 0);
  }
  to {
    transform: translate3d(63vw, -113vh, 0);
  }
}
.circle-container:nth-child(162) .circle {
  -webkit-animation-delay: 1213ms;
          animation-delay: 1213ms;
}
.circle-container:nth-child(163) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-163;
          animation-name: move-frames-163;
  -webkit-animation-duration: 31120ms;
          animation-duration: 31120ms;
  -webkit-animation-delay: 4244ms;
          animation-delay: 4244ms;
}
@-webkit-keyframes move-frames-163 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -110vh, 0);
  }
}
@keyframes move-frames-163 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -110vh, 0);
  }
}
.circle-container:nth-child(163) .circle {
  -webkit-animation-delay: 1876ms;
          animation-delay: 1876ms;
}
.circle-container:nth-child(164) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-164;
          animation-name: move-frames-164;
  -webkit-animation-duration: 32266ms;
          animation-duration: 32266ms;
  -webkit-animation-delay: 22527ms;
          animation-delay: 22527ms;
}
@-webkit-keyframes move-frames-164 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -114vh, 0);
  }
}
@keyframes move-frames-164 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -114vh, 0);
  }
}
.circle-container:nth-child(164) .circle {
  -webkit-animation-delay: 216ms;
          animation-delay: 216ms;
}
.circle-container:nth-child(165) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-165;
          animation-name: move-frames-165;
  -webkit-animation-duration: 29539ms;
          animation-duration: 29539ms;
  -webkit-animation-delay: 29968ms;
          animation-delay: 29968ms;
}
@-webkit-keyframes move-frames-165 {
  from {
    transform: translate3d(50vw, 110vh, 0);
  }
  to {
    transform: translate3d(26vw, -116vh, 0);
  }
}
@keyframes move-frames-165 {
  from {
    transform: translate3d(50vw, 110vh, 0);
  }
  to {
    transform: translate3d(26vw, -116vh, 0);
  }
}
.circle-container:nth-child(165) .circle {
  -webkit-animation-delay: 3657ms;
          animation-delay: 3657ms;
}
.circle-container:nth-child(166) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-166;
          animation-name: move-frames-166;
  -webkit-animation-duration: 32568ms;
          animation-duration: 32568ms;
  -webkit-animation-delay: 8496ms;
          animation-delay: 8496ms;
}
@-webkit-keyframes move-frames-166 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(60vw, -113vh, 0);
  }
}
@keyframes move-frames-166 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(60vw, -113vh, 0);
  }
}
.circle-container:nth-child(166) .circle {
  -webkit-animation-delay: 325ms;
          animation-delay: 325ms;
}
.circle-container:nth-child(167) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-167;
          animation-name: move-frames-167;
  -webkit-animation-duration: 29955ms;
          animation-duration: 29955ms;
  -webkit-animation-delay: 25970ms;
          animation-delay: 25970ms;
}
@-webkit-keyframes move-frames-167 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -123vh, 0);
  }
}
@keyframes move-frames-167 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -123vh, 0);
  }
}
.circle-container:nth-child(167) .circle {
  -webkit-animation-delay: 3746ms;
          animation-delay: 3746ms;
}
.circle-container:nth-child(168) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-168;
          animation-name: move-frames-168;
  -webkit-animation-duration: 34360ms;
          animation-duration: 34360ms;
  -webkit-animation-delay: 7889ms;
          animation-delay: 7889ms;
}
@-webkit-keyframes move-frames-168 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -129vh, 0);
  }
}
@keyframes move-frames-168 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -129vh, 0);
  }
}
.circle-container:nth-child(168) .circle {
  -webkit-animation-delay: 2569ms;
          animation-delay: 2569ms;
}
.circle-container:nth-child(169) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-169;
          animation-name: move-frames-169;
  -webkit-animation-duration: 33678ms;
          animation-duration: 33678ms;
  -webkit-animation-delay: 27555ms;
          animation-delay: 27555ms;
}
@-webkit-keyframes move-frames-169 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
@keyframes move-frames-169 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
.circle-container:nth-child(169) .circle {
  -webkit-animation-delay: 1450ms;
          animation-delay: 1450ms;
}
.circle-container:nth-child(170) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-170;
          animation-name: move-frames-170;
  -webkit-animation-duration: 36695ms;
          animation-duration: 36695ms;
  -webkit-animation-delay: 36225ms;
          animation-delay: 36225ms;
}
@-webkit-keyframes move-frames-170 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(12vw, -119vh, 0);
  }
}
@keyframes move-frames-170 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(12vw, -119vh, 0);
  }
}
.circle-container:nth-child(170) .circle {
  -webkit-animation-delay: 3520ms;
          animation-delay: 3520ms;
}
.circle-container:nth-child(171) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-171;
          animation-name: move-frames-171;
  -webkit-animation-duration: 36182ms;
          animation-duration: 36182ms;
  -webkit-animation-delay: 7707ms;
          animation-delay: 7707ms;
}
@-webkit-keyframes move-frames-171 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -109vh, 0);
  }
}
@keyframes move-frames-171 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -109vh, 0);
  }
}
.circle-container:nth-child(171) .circle {
  -webkit-animation-delay: 2602ms;
          animation-delay: 2602ms;
}
.circle-container:nth-child(172) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-172;
          animation-name: move-frames-172;
  -webkit-animation-duration: 29005ms;
          animation-duration: 29005ms;
  -webkit-animation-delay: 13973ms;
          animation-delay: 13973ms;
}
@-webkit-keyframes move-frames-172 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -119vh, 0);
  }
}
@keyframes move-frames-172 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -119vh, 0);
  }
}
.circle-container:nth-child(172) .circle {
  -webkit-animation-delay: 321ms;
          animation-delay: 321ms;
}
.circle-container:nth-child(173) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-173;
          animation-name: move-frames-173;
  -webkit-animation-duration: 33915ms;
          animation-duration: 33915ms;
  -webkit-animation-delay: 28528ms;
          animation-delay: 28528ms;
}
@-webkit-keyframes move-frames-173 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(7vw, -114vh, 0);
  }
}
@keyframes move-frames-173 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(7vw, -114vh, 0);
  }
}
.circle-container:nth-child(173) .circle {
  -webkit-animation-delay: 2021ms;
          animation-delay: 2021ms;
}
.circle-container:nth-child(174) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-174;
          animation-name: move-frames-174;
  -webkit-animation-duration: 31430ms;
          animation-duration: 31430ms;
  -webkit-animation-delay: 26152ms;
          animation-delay: 26152ms;
}
@-webkit-keyframes move-frames-174 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}
@keyframes move-frames-174 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}
.circle-container:nth-child(174) .circle {
  -webkit-animation-delay: 2492ms;
          animation-delay: 2492ms;
}
.circle-container:nth-child(175) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-175;
          animation-name: move-frames-175;
  -webkit-animation-duration: 31708ms;
          animation-duration: 31708ms;
  -webkit-animation-delay: 2483ms;
          animation-delay: 2483ms;
}
@-webkit-keyframes move-frames-175 {
  from {
    transform: translate3d(5vw, 108vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
@keyframes move-frames-175 {
  from {
    transform: translate3d(5vw, 108vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
.circle-container:nth-child(175) .circle {
  -webkit-animation-delay: 3636ms;
          animation-delay: 3636ms;
}
.circle-container:nth-child(176) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-176;
          animation-name: move-frames-176;
  -webkit-animation-duration: 31344ms;
          animation-duration: 31344ms;
  -webkit-animation-delay: 14260ms;
          animation-delay: 14260ms;
}
@-webkit-keyframes move-frames-176 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(59vw, -119vh, 0);
  }
}
@keyframes move-frames-176 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(59vw, -119vh, 0);
  }
}
.circle-container:nth-child(176) .circle {
  -webkit-animation-delay: 2959ms;
          animation-delay: 2959ms;
}
.circle-container:nth-child(177) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-177;
          animation-name: move-frames-177;
  -webkit-animation-duration: 36411ms;
          animation-duration: 36411ms;
  -webkit-animation-delay: 3495ms;
          animation-delay: 3495ms;
}
@-webkit-keyframes move-frames-177 {
  from {
    transform: translate3d(68vw, 105vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
@keyframes move-frames-177 {
  from {
    transform: translate3d(68vw, 105vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
.circle-container:nth-child(177) .circle {
  -webkit-animation-delay: 3325ms;
          animation-delay: 3325ms;
}
.circle-container:nth-child(178) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-178;
          animation-name: move-frames-178;
  -webkit-animation-duration: 36271ms;
          animation-duration: 36271ms;
  -webkit-animation-delay: 18545ms;
          animation-delay: 18545ms;
}
@-webkit-keyframes move-frames-178 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(52vw, -125vh, 0);
  }
}
@keyframes move-frames-178 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(52vw, -125vh, 0);
  }
}
.circle-container:nth-child(178) .circle {
  -webkit-animation-delay: 1561ms;
          animation-delay: 1561ms;
}
.circle-container:nth-child(179) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-179;
          animation-name: move-frames-179;
  -webkit-animation-duration: 32331ms;
          animation-duration: 32331ms;
  -webkit-animation-delay: 12986ms;
          animation-delay: 12986ms;
}
@-webkit-keyframes move-frames-179 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -137vh, 0);
  }
}
@keyframes move-frames-179 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -137vh, 0);
  }
}
.circle-container:nth-child(179) .circle {
  -webkit-animation-delay: 1929ms;
          animation-delay: 1929ms;
}
.circle-container:nth-child(180) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-180;
          animation-name: move-frames-180;
  -webkit-animation-duration: 32749ms;
          animation-duration: 32749ms;
  -webkit-animation-delay: 688ms;
          animation-delay: 688ms;
}
@-webkit-keyframes move-frames-180 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }
  to {
    transform: translate3d(48vw, -133vh, 0);
  }
}
@keyframes move-frames-180 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }
  to {
    transform: translate3d(48vw, -133vh, 0);
  }
}
.circle-container:nth-child(180) .circle {
  -webkit-animation-delay: 295ms;
          animation-delay: 295ms;
}
.circle-container:nth-child(181) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-181;
          animation-name: move-frames-181;
  -webkit-animation-duration: 30605ms;
          animation-duration: 30605ms;
  -webkit-animation-delay: 35661ms;
          animation-delay: 35661ms;
}
@-webkit-keyframes move-frames-181 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(47vw, -112vh, 0);
  }
}
@keyframes move-frames-181 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(47vw, -112vh, 0);
  }
}
.circle-container:nth-child(181) .circle {
  -webkit-animation-delay: 1656ms;
          animation-delay: 1656ms;
}
.circle-container:nth-child(182) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-182;
          animation-name: move-frames-182;
  -webkit-animation-duration: 31423ms;
          animation-duration: 31423ms;
  -webkit-animation-delay: 25086ms;
          animation-delay: 25086ms;
}
@-webkit-keyframes move-frames-182 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
@keyframes move-frames-182 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
.circle-container:nth-child(182) .circle {
  -webkit-animation-delay: 1463ms;
          animation-delay: 1463ms;
}
.circle-container:nth-child(183) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-183;
          animation-name: move-frames-183;
  -webkit-animation-duration: 30725ms;
          animation-duration: 30725ms;
  -webkit-animation-delay: 26106ms;
          animation-delay: 26106ms;
}
@-webkit-keyframes move-frames-183 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
@keyframes move-frames-183 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
.circle-container:nth-child(183) .circle {
  -webkit-animation-delay: 767ms;
          animation-delay: 767ms;
}
.circle-container:nth-child(184) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-184;
          animation-name: move-frames-184;
  -webkit-animation-duration: 31622ms;
          animation-duration: 31622ms;
  -webkit-animation-delay: 11044ms;
          animation-delay: 11044ms;
}
@-webkit-keyframes move-frames-184 {
  from {
    transform: translate3d(43vw, 103vh, 0);
  }
  to {
    transform: translate3d(18vw, -106vh, 0);
  }
}
@keyframes move-frames-184 {
  from {
    transform: translate3d(43vw, 103vh, 0);
  }
  to {
    transform: translate3d(18vw, -106vh, 0);
  }
}
.circle-container:nth-child(184) .circle {
  -webkit-animation-delay: 713ms;
          animation-delay: 713ms;
}
.circle-container:nth-child(185) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-185;
          animation-name: move-frames-185;
  -webkit-animation-duration: 36521ms;
          animation-duration: 36521ms;
  -webkit-animation-delay: 35567ms;
          animation-delay: 35567ms;
}
@-webkit-keyframes move-frames-185 {
  from {
    transform: translate3d(47vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -124vh, 0);
  }
}
@keyframes move-frames-185 {
  from {
    transform: translate3d(47vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -124vh, 0);
  }
}
.circle-container:nth-child(185) .circle {
  -webkit-animation-delay: 2424ms;
          animation-delay: 2424ms;
}
.circle-container:nth-child(186) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-186;
          animation-name: move-frames-186;
  -webkit-animation-duration: 31507ms;
          animation-duration: 31507ms;
  -webkit-animation-delay: 28351ms;
          animation-delay: 28351ms;
}
@-webkit-keyframes move-frames-186 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-186 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(186) .circle {
  -webkit-animation-delay: 763ms;
          animation-delay: 763ms;
}
.circle-container:nth-child(187) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-187;
          animation-name: move-frames-187;
  -webkit-animation-duration: 30925ms;
          animation-duration: 30925ms;
  -webkit-animation-delay: 6229ms;
          animation-delay: 6229ms;
}
@-webkit-keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 105vh, 0);
  }
  to {
    transform: translate3d(3vw, -121vh, 0);
  }
}
@keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 105vh, 0);
  }
  to {
    transform: translate3d(3vw, -121vh, 0);
  }
}
.circle-container:nth-child(187) .circle {
  -webkit-animation-delay: 1578ms;
          animation-delay: 1578ms;
}
.circle-container:nth-child(188) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-188;
          animation-name: move-frames-188;
  -webkit-animation-duration: 35079ms;
          animation-duration: 35079ms;
  -webkit-animation-delay: 3601ms;
          animation-delay: 3601ms;
}
@-webkit-keyframes move-frames-188 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
@keyframes move-frames-188 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
.circle-container:nth-child(188) .circle {
  -webkit-animation-delay: 3632ms;
          animation-delay: 3632ms;
}
.circle-container:nth-child(189) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-189;
          animation-name: move-frames-189;
  -webkit-animation-duration: 28054ms;
          animation-duration: 28054ms;
  -webkit-animation-delay: 21434ms;
          animation-delay: 21434ms;
}
@-webkit-keyframes move-frames-189 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }
  to {
    transform: translate3d(45vw, -135vh, 0);
  }
}
@keyframes move-frames-189 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }
  to {
    transform: translate3d(45vw, -135vh, 0);
  }
}
.circle-container:nth-child(189) .circle {
  -webkit-animation-delay: 3316ms;
          animation-delay: 3316ms;
}
.circle-container:nth-child(190) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-190;
          animation-name: move-frames-190;
  -webkit-animation-duration: 34427ms;
          animation-duration: 34427ms;
  -webkit-animation-delay: 7991ms;
          animation-delay: 7991ms;
}
@-webkit-keyframes move-frames-190 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -115vh, 0);
  }
}
@keyframes move-frames-190 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -115vh, 0);
  }
}
.circle-container:nth-child(190) .circle {
  -webkit-animation-delay: 3656ms;
          animation-delay: 3656ms;
}
.circle-container:nth-child(191) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-191;
          animation-name: move-frames-191;
  -webkit-animation-duration: 29405ms;
          animation-duration: 29405ms;
  -webkit-animation-delay: 9248ms;
          animation-delay: 9248ms;
}
@-webkit-keyframes move-frames-191 {
  from {
    transform: translate3d(6vw, 107vh, 0);
  }
  to {
    transform: translate3d(39vw, -120vh, 0);
  }
}
@keyframes move-frames-191 {
  from {
    transform: translate3d(6vw, 107vh, 0);
  }
  to {
    transform: translate3d(39vw, -120vh, 0);
  }
}
.circle-container:nth-child(191) .circle {
  -webkit-animation-delay: 1842ms;
          animation-delay: 1842ms;
}
.circle-container:nth-child(192) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-192;
          animation-name: move-frames-192;
  -webkit-animation-duration: 35927ms;
          animation-duration: 35927ms;
  -webkit-animation-delay: 28403ms;
          animation-delay: 28403ms;
}
@-webkit-keyframes move-frames-192 {
  from {
    transform: translate3d(51vw, 108vh, 0);
  }
  to {
    transform: translate3d(82vw, -120vh, 0);
  }
}
@keyframes move-frames-192 {
  from {
    transform: translate3d(51vw, 108vh, 0);
  }
  to {
    transform: translate3d(82vw, -120vh, 0);
  }
}
.circle-container:nth-child(192) .circle {
  -webkit-animation-delay: 1682ms;
          animation-delay: 1682ms;
}
.circle-container:nth-child(193) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-193;
          animation-name: move-frames-193;
  -webkit-animation-duration: 33953ms;
          animation-duration: 33953ms;
  -webkit-animation-delay: 23166ms;
          animation-delay: 23166ms;
}
@-webkit-keyframes move-frames-193 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -120vh, 0);
  }
}
@keyframes move-frames-193 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -120vh, 0);
  }
}
.circle-container:nth-child(193) .circle {
  -webkit-animation-delay: 1410ms;
          animation-delay: 1410ms;
}
.circle-container:nth-child(194) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-194;
          animation-name: move-frames-194;
  -webkit-animation-duration: 34842ms;
          animation-duration: 34842ms;
  -webkit-animation-delay: 8590ms;
          animation-delay: 8590ms;
}
@-webkit-keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
@keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
.circle-container:nth-child(194) .circle {
  -webkit-animation-delay: 2118ms;
          animation-delay: 2118ms;
}
.circle-container:nth-child(195) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-195;
          animation-name: move-frames-195;
  -webkit-animation-duration: 29574ms;
          animation-duration: 29574ms;
  -webkit-animation-delay: 2506ms;
          animation-delay: 2506ms;
}
@-webkit-keyframes move-frames-195 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -125vh, 0);
  }
}
@keyframes move-frames-195 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -125vh, 0);
  }
}
.circle-container:nth-child(195) .circle {
  -webkit-animation-delay: 2245ms;
          animation-delay: 2245ms;
}
.circle-container:nth-child(196) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-196;
          animation-name: move-frames-196;
  -webkit-animation-duration: 31999ms;
          animation-duration: 31999ms;
  -webkit-animation-delay: 17148ms;
          animation-delay: 17148ms;
}
@-webkit-keyframes move-frames-196 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
@keyframes move-frames-196 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
.circle-container:nth-child(196) .circle {
  -webkit-animation-delay: 507ms;
          animation-delay: 507ms;
}
.circle-container:nth-child(197) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-197;
          animation-name: move-frames-197;
  -webkit-animation-duration: 28019ms;
          animation-duration: 28019ms;
  -webkit-animation-delay: 30775ms;
          animation-delay: 30775ms;
}
@-webkit-keyframes move-frames-197 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }
  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}
@keyframes move-frames-197 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }
  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}
.circle-container:nth-child(197) .circle {
  -webkit-animation-delay: 2935ms;
          animation-delay: 2935ms;
}
.circle-container:nth-child(198) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-198;
          animation-name: move-frames-198;
  -webkit-animation-duration: 29017ms;
          animation-duration: 29017ms;
  -webkit-animation-delay: 8183ms;
          animation-delay: 8183ms;
}
@-webkit-keyframes move-frames-198 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(18vw, -121vh, 0);
  }
}
@keyframes move-frames-198 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(18vw, -121vh, 0);
  }
}
.circle-container:nth-child(198) .circle {
  -webkit-animation-delay: 1155ms;
          animation-delay: 1155ms;
}
.circle-container:nth-child(199) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-199;
          animation-name: move-frames-199;
  -webkit-animation-duration: 36060ms;
          animation-duration: 36060ms;
  -webkit-animation-delay: 34040ms;
          animation-delay: 34040ms;
}
@-webkit-keyframes move-frames-199 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(60vw, -125vh, 0);
  }
}
@keyframes move-frames-199 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(60vw, -125vh, 0);
  }
}
.circle-container:nth-child(199) .circle {
  -webkit-animation-delay: 395ms;
          animation-delay: 395ms;
}
.circle-container:nth-child(200) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-200;
          animation-name: move-frames-200;
  -webkit-animation-duration: 35811ms;
          animation-duration: 35811ms;
  -webkit-animation-delay: 3623ms;
          animation-delay: 3623ms;
}
@-webkit-keyframes move-frames-200 {
  from {
    transform: translate3d(70vw, 110vh, 0);
  }
  to {
    transform: translate3d(98vw, -111vh, 0);
  }
}
@keyframes move-frames-200 {
  from {
    transform: translate3d(70vw, 110vh, 0);
  }
  to {
    transform: translate3d(98vw, -111vh, 0);
  }
}
.circle-container:nth-child(200) .circle {
  -webkit-animation-delay: 1153ms;
          animation-delay: 1153ms;
}
@-webkit-keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.wallet_add {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #32a8df;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
}
.after_login_dropdown {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
}
.after_login_dropdown ul {
  list-style: none;
  padding: 0;
}
.after_login_dropdown ul li {
  color: #000;
  font-size: 14px;
  padding: 5px 0;
  font-weight: 500;
}
.after_login_dropdown ul li a {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
button.token_btn select.custom-select,
button.token_btn select.custom-select:focus {
  background: transparent url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center !important;
  border: none;
  box-shadow: none;
  height: unset;
  padding-top: 0;
  padding-bottom: 0;
}
.short_address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}
.unlock_icon img {
  width: 30px;
}
.unlock_icon {
  width: 64px;
  border: 4px solid #32a8df;
  height: 64px;
  display: flex;
  background: #32a8df;
  box-shadow: rgb(9 22 46 / 10%) 0px 2px 5px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin: 0 auto 15px;
}
button.primary_btn.blue_btn.Mui-disabled {
  color: #b7becb;
  background-color: #eff3fb;
  border-color: #eff3fb;
}
.p2p_order_tab {
  padding: 0;
}
.nav-tabs.p2p_order_tab .nav-item .nav-link { 
  text-transform: capitalize;
  font-size: 15px;
}
.rdt_TableCol {
  background: #fff;
}
.rdt_TableCol_Sortable {
  color: #000 !important;
}
.rdt_TableCell {
  background-color: #fff;
}
.rdt_TableCell {
  color: #646e88;
}
.rdt_TableHeadRow {
  border-bottom: 1px solid #e9eaec !important;
}

/* Media Queries */
@media screen and (max-width:1300px) {
  .top_menu {
    left: 13px;
  }
}
@media screen and (max-width: 1279px) {
  .home_page_menu ul {
    display: block;
  }
  .home_page_menu ul li {
    float: none;
  }
  .home_page_menu ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .home_page_menu ul li a i {
    font-size: 24px;
  }
  .home_page_menu ul li a.active,
  .home_page_menu ul li a:hover {
    color: #32a8df;
  }
  li.menu_dropdown {
    padding: 8px 12px;
  }
  .theme_switch_btn {
    margin: 16px 12px;
  }
  .noti_ul button {
    padding: 12px 15px !important;
  }
  button.auth_btn {
    margin: 10px 16px;
  }
  .page-header {
    height: 100%;
    padding: 50px 0;
  }
  .sticky_left_nav {
    display: none;
  }
  .brand_logo_mobile {
    display: block;
  }
  .exchange_div::before,
  .liquidity_div::before {
    right: 0;
  }
  .whitebox.swap_box {
    width: 80%;
    margin: 30px auto;
  }
  .banner_ticker_panel .slick-prev {
    left: -20px
  }
  .banner_ticker_panel .slick-next {
    right: -20px;
  }
}
@media screen and (max-width: 1199px) {
  .banner_ticker_panel .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .mobile_app_banner {
    margin-top: 80px;
  }
  .media{
    flex-wrap:wrap;
  }
}
@media screen and (max-width: 991px) {
  .list_view_single {
    display: block;
  }
  .list_view_single_first {
    margin-bottom: 10px;
    justify-content: center;
  }
  .list_view_single_third .accordian_link {
    justify-content: center;
  }
  .referral_steps h2 {
    padding: 0;
    font-size: 28px;
  }
  .referral_arrow {
    display: none;
  }
  .referral_steps_single h3 {
    font-size: 18px;
  }
  .referral_steps_single p {    
    font-size: 14px;
  }
  .getstarted_section h2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 959px) {
  .exchange_div{
    width: 100%;
  }
  .whitebox.swap_box{
    width: 100%;
  }
  .features_section::before{
    display: none;
  }
  .page-header h1 {
    font-size: 36px;
  }
  .page-header p {
    margin-bottom: 30px;
  }
  .advantages_section_first {
    height: 100%;
  }
  .main_content {
    color: #202020;
  }
  .footer_top_home .subscribe_label {
    text-align: left;
    color: #fff;
    margin: 20px 0 10px;
  }
  .footer_social_links {
    text-align: left;
  }
  .footer_top_home h2 {
    margin: 15px 0 0;
  }
  .footer_menu_list li a {    
    display: inline-block;
  }
  .inner_vertical_menu_slider .slick-slide {
    float: left;
  }
  .inner_vertical_menu {
    padding: 0 40px;
    min-height: initial;
  }
  .inner_pageheader {
    min-height: initial;
  }
  .inner_vertical_menu.nav-pills .nav-link {
    padding: 15px 10px 15px 10px;    
    text-align: center;
  }  
  .footer_bottom_home {
    display: block;
  }
  .copyryt_txt {
    margin-top: 15px;
  }
  .pools_filter {
    display: block;
  }
  .pools_filter_left {
    margin-bottom: 15px;
    justify-content: center;
  }
  .list_view_single_card_body {
    display: block;
  }
  .list_view_single_card_body_left {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .features_section {
    text-align: center;
    overflow: hidden;
  }
  .feature_panel_single .media {
    display: block;
  }
  .feature_panel_single .media-body {
    padding: 10px;
  }
  .feature_panel_single .media img {
    margin-top: 15px;
  }  
  .main_title {
    font-size: 24px;
  }
  .rewards_section h2 {    
    font-size: 18px;
  }
  .page-header h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .page-header h3 {
    font-size: 18px;
  }
  .banner_form_card .tab-content {
    padding: 25px 20px 20px;
  }
  .banner_form_card .card-body .nav-tabs {  
    padding-left: 20px;
  }
  .banner_form_card h2.card-title {    
    font-size: 20px;
    padding-left: 25px;
  }
  .history_table_filter {
    display: block;
  }
  .history_table_filter_left {
    display: block;
  }
  .search_grp_inp {
    width: 100%;
    margin: 15px 0;
  }  
  .input_group_prepend {
    top: 11px;
  }
  .launchPadCoinDetails {
		display: block;
	}
	.cdTeamDetail ul {
		flex-wrap: wrap;
	}
	.cdTeamDetail ul li {
		margin: 15px 0 0;
	}
	.social h4 {
		justify-content: center;
	}
	.launchPadCoinDetails h4 {
		font-size: 14px;		
		line-height: 22px;
		text-align: center;
	}
  .exchange_div::before,
  .liquidity_div::before {
    display: none;
  }
  .whitebox.swap_box {
    width: 100%;    
    margin: 0 auto !important;
  }
  .pools_filter_left {
    display: block;
    text-align: center;
  }
  .pools_filter_right {
    display: block;
    text-align: center;
  }
  .pools_filter_right .custom-select {
    margin:0 0 10px;
  }
  .pool_search {
    margin-bottom: 10px;
    height: 40px;
    width: 100%;
  }
  .view_btn_grp {
    justify-content: center;
  }
  .list_view_single_second {
    display: block;
  }
  .list_view_single_second > div {
    margin: 15px 0 0;
  }
  .list_view_single_first,
  .list_view_single_third .accordian_link {
    justify-content: flex-start;
  }
  .list_view_single_third {
    margin-top: 15px;
  }
  .list_view_single_card_body_right {
    display: block;
  }
  .list_view_start_stake {
    margin: 15px 0 0 0;
    width: 100%;
  }
  .list_view_single_card_body_right .input_panel div.d-flex:nth-child(2) {
    display: block !important;
  }
  .list_view_single_card_body_right button.harvest_btn {
    margin-top: 10px;
  }
  .inner_heading_wrapper h2 {
    font-size: 36px;
    line-height: 36px;
  }
  .inner_heading_wrapper p {
    font-size: 16px;
  }
  .total_referral {
    font-size: 36px;
  }
  .referral_steps_panel {
    justify-content: center;
  }
  .swap_info_end > div {
    display: block;
  }
  .starts_section .d-flex.align-items-center {
    display: block !important;
    text-align: center;
  }
  .starts_sec_btn {
    text-align: center;
    margin-top: 10px;
  }
  .starts_section h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .getstarted_section .d-flex {
    display: block !important;
  }
  .page-header .d-flex {
    display: block !important;
  }
  .banner_ticker_panel .slick-next {
    right: -10px;
  }
  .banner_ticker_panel .slick-prev {
    left: -10px;
  }
  .banner_ticker_single {
    width:100% !important;
  }
}
@media screen and (max-width: 575px) { 
  .history_table_div h2{
    padding-left: 20px;
  }
  .projectIntroduction li span {
		width: 100%;
		display: block;
	}
  .socialMediaCoinDetails ul {
		flex-wrap: wrap;
	}
	.socialMediaCoinDetails ul li {
		margin-top: 15px;
	}
  .whitebox > .d-flex {
    display: block !important;
  }
  .chart_btns button {
    padding: 5px 6px;
    font-size: 12px;
  }
  input.custom_inp {
    width: 140px;
  }
  .trade_wrap_title {
    display: block;
  }
  .trade_wrap_title > div {
    margin-bottom: 10px;
  }
  .trade_notes > div span {
    font-size: 13px;
  }
}
@media screen and (max-width:360px) {
  .history_table_div h2{
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
 }
 

 .loading{  
  fill: #32a8df !important;
 }

 button.primary_btn.blue_btn {
  padding: 10px;
 }
 .butnchg {
  flex-wrap: wrap;
 }
 .loader{
  margin: 10px auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
 }
 .butnchg .loader{
  margin: 10px auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
 }
 .butnchg  button.primary_btn.blue_btn {
  width: 48%;
 }
 .tablechg{
  justify-content: space-between;
  width: 100%;
 }
 .headchg{
  position: relative;
 }
 .headchg .fa-arrow-left{
position: absolute;
color: #000;
 }
 .headchg .importpoolh2{
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #292f45;
  margin: 0;
  width: 100%;
  text-align: center;
 }
 .headchg .tooltip_icon{
  position: absolute;
  right:0;
 }
 .balancechg{
  margin-bottom: 0;
 }
.modalchg{
  padding: 20px;
}
.modalchg .arrow{  
  background: black;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  top:37%;
  left: 48%;
}
.modalchg  .modal-header h5{
  font-size: 24px;
}
.modalchg .modal-header .close{
  color: #000;
}
.modalchg  
.modalbelow{
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.modalchg  
.modalbelow .custome-swap-data{
margin: 8px 0;
}
.MuiSlider-markLabel:nth-child(1){
  left: 2% !important;
}
.slide{
  padding: 0 10px;
}
.closeicon{  
  background: #32a8df;
  border-radius: 2px;
}
.closeicon i{
  color: #fff;
}
.stake_btn_grps button {
  color: black;
  line-height: 15px;
  font-size: 30px;
  background-color: #c0e2f2;
  margin: 5px;
  padding: 10px;
  min-width: auto;
}
.stake_btn_grps button:hover {
  text-decoration: none;
  background-color: #c0e2f2;
}
 @media screen and (max-width: 574px)
{
  .headchg{
    display: flex !important;
  }
  .modalchg .modalbelow .custome-swap-data {
    margin: 8px 0;
    flex-direction: column;
    align-items: baseline !important;
}
}
 @media screen and (max-width:400px) {
  .butnchg {
    flex-direction: column;
   }
   .butnchg .spacing{
    margin-top: 15px;
   }
 }